import mutations, { SET_ONBOARDING_CURRENT_STEP } from '@/store/mutation-types';
import { debounce } from 'lodash';
import ApiRepository from '@/api-repository';
import router from '@/routes';
import stripIndent from 'strip-indent';
import { AGENCY_DU, PLATFORM_FACEBOOK } from '@/helpers/globals.js';
import { getChannelByBBProductId } from '@/components/onboarding/channel-setup/channels.js';

const apiRepository = new ApiRepository();

const startNewOnboarding = async({ dispatch, rootState, rootGetters }, params) => {
    const {
        dealerId,
        redirectUrl = null,
        productIds = null,
        playId = null
    } = params;

    // If the dealer being onboarded is not the currently loaded dealer
    // switch and load the correct one
    if (dealerId !== rootState.dealer.currentDealer.id) {
        await dispatch('updateDealerById', dealerId);
    }

    // Update the feature status and allow the cached status to be used
    await dispatch('updateDealerFeatureStatus', { cached: true });

    // Upgrade the dealer to event flow 2.0 if one of the products need it
    await dispatch('upgradeToEventFlow', {
        dealerId,
        productIds
    });

    // Setup the data for the new onboarding record
    const data = {
        dealer_id: dealerId,
        products: productIds,
        configuration: {
            redirectUrl,
            existingFeatures: rootGetters.dealerFeaturesComplete,
            currentStep: 0,
            latestStep: 0,
            data: {}
        }
    };

    if (playId) {
        data.play_id = playId;
    }

    if(data.products.length) {
        data.product_id = data.products[0];
    }

    // Create the onboarding record
    const response = await apiRepository.createDealerProductOnboarding(data);
    const onboarding = response.data.data;

    //Create the dealer_product_onboarding_products objects
    const promises = productIds.map(productId => {
        return apiRepository.createDealerProductOnboardingProduct({
            dealer_product_onboarding_id: onboarding.id,
            product_id: productId
        });
    });

    await Promise.all(promises);

    // Get the product from the product ids
    const platforms = productIds.map(productId => {
        return getChannelByBBProductId(productId);
    });

    const isAdmin = window.userHasRole(['super_admin', 'admin']);

    // Check where we need to redirect the user
    if (rootState.agency.currentAgency.id !== AGENCY_DU && platforms?.[0]?.key !== PLATFORM_FACEBOOK && !isAdmin) {
        // Redirect to the account settings page
        await router.pushAsync({
            name: 'dealer',
            params: {
                dealer_id: dealerId
            }
        });
        return;
    }

    // Now redirect to the onboarding page but await for external consumers
    await router.pushAsync({
        name: 'dealer-onboarding',
        params: {
            dealer_id: dealerId,
            onboarding_id: onboarding.id,
        }
    });

};

const loadOnboarding = async({ dispatch, getters, commit, rootGetters }, params) => {
    const {
        onboardingId,
        stepKey = null,
        redirectUrl = null,
    } = params;

    commit(mutations.SET_ONBOARDING_INITIALIZING, true);

    // Ensure the dealer is loaded (otherwise our state could be wrong)
    await dispatch('waitForDealerLoaded');

    // Refresh the onboarding to ensure we have up to date data
    const onboarding = await dispatch('getOnboarding', onboardingId);

    const { configuration } = onboarding;

    commit(mutations.SET_ONBOARDING_ID, onboardingId);
    commit(mutations.SET_ONBOARDING_CURRENT_STEP, configuration.currentStep ?? 0);
    commit(mutations.SET_ONBOARDING_LATEST_STEP, configuration.latestStep ?? 0);
    commit(mutations.SET_ONBOARDING_DATA, configuration.data ?? {});
    commit(mutations.SET_ONBOARDING_REDIRECT_URL, configuration.redirectUrl ?? redirectUrl);

    // Default to a product onboarding
    let onboardingType = 'product';

    // If we were supplied a play ID we need to initialize some additional
    // data since play onboarding requires certain features to be completed
    // before the play can be deployed.  We get those from the play config
    // in addition we will need data on the play for config purposes
    if (onboarding.play_id) {
        onboardingType = 'play';

        // Initialize the play data into the store
        commit(mutations.SET_ONBOARDING_PLAY_ID, onboarding.play_id);
        commit(mutations.SET_ONBOARDING_PLAY, onboarding.play);

        // Go ahead and get the campaign data for the play
        await dispatch('updateOnboardingPlayCampaigns');
    }

    // If the onboarding is for an individual product ensure the product gets set
    // This is mainly used for display purposes since the features come from the
    // aggregate products under the dealer
    if (onboarding?.products?.data?.length) {
        commit(mutations.SET_ONBOARDING_PRODUCT_IDS, onboarding.products.data.map(product => product.id));
        commit(mutations.SET_ONBOARDING_PRODUCTS, onboarding.products.data);
    }

    // Ensure the resulting type is initialized - this controls many
    // of the differing behaviors between a standard onboarding and a
    // play onboarding
    commit(mutations.SET_ONBOARDING_TYPE, onboardingType);

    // Update the feature status and allow the cached status to be used (if available)
    await dispatch('updateDealerFeatureStatus', {
        reset: true,
        cached: true,
        features: getters.applicableFeatures
    });

    // Let's check all the features that were completed when the onboarding was created
    // to make sure they're still in a "complete" state.  We do this on load and once
    // more before completion
    const { existingFeatures = [] } = configuration;
    const completedExistingFeatures = existingFeatures.filter(feature => {
        return rootGetters.dealerFeaturesComplete.includes(feature);
    });
    commit(mutations.SET_ONBOARDING_EXISTING_FEATURES, completedExistingFeatures || []);

    // If the user is being dropped on the first step and the first incomplete step
    // is greater progress to user to that step
    if (configuration.currentStep == 0 && getters.firstIncompleteStep > 0 && !stepKey) {
        commit(mutations.SET_ONBOARDING_CURRENT_STEP, getters.firstIncompleteStep);
        commit(mutations.SET_ONBOARDING_LATEST_STEP, getters.firstIncompleteStep);
    }

     // If the step has been defined in the route go to it
     if (stepKey) {
        dispatch('goToStepByKey', stepKey);
    }

    commit(mutations.SET_ONBOARDING_INITIALIZING, false);
};


const updateOnboardingPlayById = async({ commit }, playId) => {
    try {
        commit(mutations.SET_ONBOARDING_PLAY_ID, parseInt(playId));
        commit(mutations.SET_ONBOARDING_PLAY_LOADING, true);
        commit(mutations.SET_ONBOARDING_ERROR, null);

        const response = await apiRepository.getPlay(playId);

        if (!response.data.data) {
            throw 'No valid play data retrieved';
        }

        commit(mutations.SET_ONBOARDING_PLAY, response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error updating onboarding play', error);
        commit(mutations.SET_ONBOARDING_ERROR, 'Error updating onboarding play');
    } finally {
        commit(mutations.SET_ONBOARDING_PLAY_LOADING, false);
    }
};

const updateOnboardingPlayCampaigns = async({ commit, state }) => {
    try {
        commit(mutations.SET_ONBOARDING_PLAY_CAMPAIGNS_LOADING, true);
        commit(mutations.SET_ONBOARDING_PLAY_CAMPAIGNS, {
            campaigns: [],
            adSets: [],
            adCreatives: [],
        });
        commit(mutations.SET_ONBOARDING_ERROR, null);

        const response = await apiRepository.getPlayCampaigns(state.playId);

        if (!response.data) {
            throw 'Could not get play campaigns from API';
        }

        // @todo Refactor this endpoint to respond with heirarchy
        commit(mutations.SET_ONBOARDING_PLAY_CAMPAIGNS, {
            campaigns: response.data.campaigns,
            adSets: response.data.ad_sets,
            adCreatives: response.data.ad_creatives,
        });
    } catch (error) {
        console.error('Error updating onboarding play status', error);
        commit(mutations.SET_ONBOARDING_ERROR, 'Error updating onboarding play status');
    } finally {
        commit(mutations.SET_ONBOARDING_PLAY_CAMPAIGNS_LOADING, false);
    }
};

const forceUpdateOnboardingStatus = async({ dispatch, commit, getters, state, rootGetters }, params = {}) => {

    commit(mutations.SET_ONBOARDING_STATUS_LOADING, true);

    // If no features were passed use the applicable features
    if (!params.features) {
        params.features = getters.applicableFeatures;
    }

    await Promise.all([
        dispatch('updateDealerFeatureStatus', params),
        dispatch('updateCurrentDealer')
    ]);

    // Allows the system to re-check completed/existing features
    if (params.updateExisting) {
        const completedExistingFeatures = state.existingFeatures.filter(feature => {
            return rootGetters.dealerFeaturesComplete.includes(feature);
        });
        commit(mutations.SET_ONBOARDING_EXISTING_FEATURES, completedExistingFeatures || []);
    }

    commit(mutations.SET_ONBOARDING_STATUS_LOADING, false);
};

const updateOnboardingStatus = debounce(forceUpdateOnboardingStatus, 500, { leading: true, trailing: false });


const handleSkipStepRequest = ({ commit, state, dispatch }) => {
    // This sequence is irrelevant once the disclaimer is approved
    if (state.data.skipDisclaimerApproved) {
        return dispatch('goToNextStep');
    }

    commit(mutations.TOGGLE_ONBOARDING_SKIP_DISCLAIMER, true);
};

const handleSkipDisclaimerApproval = ({ commit, dispatch }) => {
    commit(mutations.UPDATE_ONBOARDING_DATA, {
        skipDisclaimerApproved: true
    });
    commit(mutations.TOGGLE_ONBOARDING_SKIP_DISCLAIMER, false);
    dispatch('goToNextStep');
};

const goToNextStep = ({ state, commit, getters, dispatch }) => {

    if (getters.nextStep === null) {
        return console.error('Prevented over-navigation on next step');
    }

    // Collect the features from the current step before progressing
    const currentStepConfig = getters.currentSteps[state.currentStep];
    const recheckFeatures = currentStepConfig.dependencies;
    const isLastStep = (getters.nextStep == (getters.currentSteps.length - 1));

    commit(mutations.SET_ONBOARDING_CURRENT_STEP, getters.nextStep);

    // Update the previous step's features (if available) whenever the user progresses
    // Don't recheck on the last step since that step will always force a full recheck
    // before allowing the user to complete
    if (recheckFeatures.length && !isLastStep) {
        dispatch('updateOnboardingStatus', {
            features: recheckFeatures
        });
    }
};

const goToPreviousStep = ({ commit, getters }) => {
    if (getters.previousStep === null) {
        return console.error('Prevented over-navigation on previous step');
    }

    commit(mutations.SET_ONBOARDING_CURRENT_STEP, getters.previousStep);
};

const goToStepByFeature = ({ commit, getters }, feature) => {
    const step = getters.onboardingStepByFeature(feature);

    if (step === -1) {
        return;
    }

    commit(mutations.SET_ONBOARDING_CURRENT_STEP, step);
};

const goToStepByKey = ({ commit, getters, state }, key) => {

    const targetStep = getters.currentSteps.findIndex(step => {
        return step.key === key;
    });

    // If we're already on the step return
    if (state.currentStep === targetStep) {
        return;
    }

    // Ensure the user isn't trying to progress past the latest step
    if (targetStep > state.latestStep) {
        return;
    }

    // If the target step couldn't be found go to the next step or the first
    if (targetStep === -1) {
        commit(SET_ONBOARDING_CURRENT_STEP, getters.nextStep || 0);
    } else {
        commit(SET_ONBOARDING_CURRENT_STEP, targetStep);
    }
};

const routeToStepByFeature = ({ state, getters, rootState }, feature) => {

    const step = getters.onboardingStepByFeature(feature);
    const stepConfig = getters.currentSteps[step];

    const query = {
        step: stepConfig.key
    };
    router.push({
        name: 'dealer-onboarding',
        params: {
            onboarding_id: state.onboardingId,
            dealer_id: rootState.dealer.currentDealerId
        },
        query
    });
};

const completeOnboarding = async({ state, dispatch }, currentChannel) => {
    try {
        await dispatch('routeToOnboardingProductCompletionPage', currentChannel);

        // Mark the onboarding as completed (which also soft deletes it)
        await apiRepository.completeDealerProductOnboarding(state.onboardingId);
    } catch (e) {
        console.log(e);
    } finally {
        // Refresh the current dealer (this resolves a bug there the onboarding was re-initializing when the dealer page loaded)
        dispatch('updateCurrentDealer');

        // Update the active onboardings so any list/counts show without the completed onboarding
        dispatch('getActiveOnboardings');
        dispatch('resetOnboarding');
    }
};

const routeToOnboardingPlayCompletionPage = ({ state, rootState }, { dealerPlayId }) => {

    if (state.redirectUrl) {
        return new Promise(resolve => {
            router.push(state.redirectUrl, resolve);
        });
    }

    return new Promise(resolve => {
        router.push({
            name: 'dealer-playbooks-manage',
            params: {
                dealer_id: rootState.dealer.currentDealerId
            },
            query: {
                deployed_play: dealerPlayId,
                onboarding_complete: true
            }
        }, resolve);
    });
};

const routeToOnboardingProductCompletionPage = ({ state, rootState }, currentChannel) => {

    if (state.redirectUrl) {
        return new Promise(resolve => {
            router.push(state.redirectUrl, resolve);
        });
    }

    return new Promise(resolve => {
        const { name, id } = currentChannel;
        router.push({
            name: 'onboarding-complete',
            params: {
                dealer_id: rootState.dealer.currentDealerId
            },
            query: {
                onboarding_complete: true,
                channel: name,
                channel_id: id,
                product_ids: state.productIds
            }
        }, resolve);
    });
};

const resetOnboarding = ({ commit }) => {
    commit(mutations.SET_ONBOARDING_ID, null);
    commit(mutations.SET_ONBOARDING_DATA, {});
    commit(mutations.SET_ONBOARDING_PRODUCT_IDS, []);
    commit(mutations.SET_ONBOARDING_PRODUCTS, []);
    commit(mutations.SET_ONBOARDING_PLAY_ID, null);
    commit(mutations.SET_ONBOARDING_PLAY, null);
};

const exitOnboarding = ({ state, dispatch, rootState }) => {

    const query = {
        onboarding_exit: true
    };

    if (state.type === 'play') {
        query.play_id = state.play.id;
    } else {
        query.product_ids = state.productIds;
    }

    // Refresh the onboarding
    dispatch('getOnboarding', state.onboardingId);

    // Refresh the dealer
    dispatch('updateCurrentDealer');

    router.push({
        name: 'dealer',
        params: {
            dealer_id: rootState.dealer.currentDealerId
        },
        query
    });
};

const cancelOnboarding = async({ state, dispatch }, onboarding) => {

    // Reset the onboarding state if the id is the current onboarding
    if (state.onboardingId === onboarding.id) {
        dispatch('resetOnboarding');
    }

    // Remove the onboarding
    await apiRepository.deleteDealerProductOnboarding(onboarding.id);

    // Refresh global onboarding
    dispatch('getActiveOnboardings');
};

const cancelAllOnboardings = async({ state, dispatch, getters }) => {

    // Loop through all active onboardings and delete them
    const operations = getters.activeDealerOnboardings.map(onboarding => {

        // If the onboarding is active reset it
        if (state.onboardingId === onboarding.id) {
            dispatch('resetOnboarding');
        }

        return apiRepository.deleteDealerProductOnboarding(onboarding.id);
    });

    // Wait for all operations to complete
    await Promise.all(operations);

    // Refresh global onboarding
    dispatch('getActiveOnboardings');
};


const initActiveOnboardings = async({ dispatch, commit }) => {

    commit(mutations.SET_ONBOARDINGS_LOADING, true);

    await dispatch('getActiveOnboardings');

    // Tag the time for reference and stop loading
    commit(mutations.SET_ONBOARDINGS_UPDATED_AT, new Date());
    commit(mutations.SET_ONBOARDINGS_LOADING, false);
};

const getActiveOnboardings = async({ commit, rootState }) => {
    try {

        const response = await apiRepository.getDealerProductOnboardings(rootState.agency.currentAgency.id);
        const onboardings = response.data.data;

        commit(mutations.SET_ONBOARDINGS, onboardings);
        return onboardings;
    } catch (error) {
        console.error('Error retrieving onboardings', error);
    }
};


const getOnboarding = async({ state, commit }, id) => {
    try {
        const onboardings = [ ...state.onboardings ];

        const response = await apiRepository.getDealerProductOnboarding(id);
        const onboarding = response.data.data;

        const existingOnboardingIndex = onboardings.findIndex(existingOnboarding => {
            return (existingOnboarding.id === onboarding.id);
        });

        // Append the onboarding into the existing list since we maintain all of them at once
        if (existingOnboardingIndex !== -1) {
            onboardings[existingOnboardingIndex] = onboarding;
        } else {
            onboardings.push(onboarding);
        }

        commit(mutations.SET_ONBOARDINGS, onboardings);

        return onboarding;
    } catch (error) {
        console.error('Error retrieving onboarding', error);
    }
};

const createOnboardingSupportTicket = ({ state, dispatch }, subject) => {
    const playDescription = state.play ? `${state.play.display_name} (${state.play.id})` : 'No play';
    let subjectLine = 'Help with onboarding';
    if (subject) { subjectLine = subject }

    const content = stripIndent(`

        ## Internal Use ##
        URL: ${document.location.href}
        Play: ${playDescription}
    `);

    dispatch('createTicket', {
        subject: subjectLine,
        type: 'Onboarding - Other',
        content
    });
};


export default {
    startNewOnboarding,
    loadOnboarding,
    updateOnboardingPlayById,
    updateOnboardingPlayCampaigns,
    forceUpdateOnboardingStatus,
    updateOnboardingStatus,
    handleSkipStepRequest,
    handleSkipDisclaimerApproval,
    goToNextStep,
    goToPreviousStep,
    goToStepByFeature,
    goToStepByKey,
    exitOnboarding,
    resetOnboarding,
    completeOnboarding,
    routeToOnboardingPlayCompletionPage,
    routeToOnboardingProductCompletionPage,
    routeToStepByFeature,
    initActiveOnboardings,
    getActiveOnboardings,
    cancelOnboarding,
    cancelAllOnboardings,
    getOnboarding,
    createOnboardingSupportTicket
};
