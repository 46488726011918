<template>
    <div
        ref="card"
        :class="{
            'styled-card' : true,
            'panel-collapsed' : !isOpen,
            'centered': centered,
            'simple': simple,
            'full-height': fullHeight
        }"
        :style="wrapperStyles">
        <div
            v-if="!hideHeader"
            class="styled-card-header">
            <div
                class="styled-card-heading">
                <slot name="heading" />
            </div>

            <template
                v-if="tabs.length">
                <ul
                    class="tab-selectors"
                    :class="tabAlign">
                    <li
                        v-for="(tab,i) in tabs"
                        :key="i"
                        class="selector"
                        :class="{active: tab.name == activeTab.name}">
                        <a
                            href="#"
                            @click.prevent="currentTab = tab">
                            <span class="name">{{ tab.name }}</span>
                        </a>
                    </li>
                </ul>
            </template>

            <div class="styled-card-actions">
                <slot name="action-buttons" />
            </div>
            <button
                v-if="!hideToggle"
                class="styled-card-toggle"
                @click="isOpen = !isOpen">
                <icon
                    class="toggle-icon"
                    name="collapse" />
            </button>
        </div>
        <div class="styled-card-body">
            <template v-if="showMore">
                <div
                    class="styled-card-body-inner show-more"
                    :class="isOpen ? 'open' : null">
                    <slot />
                </div>
                <button
                    class="show-more-button"
                    @click="isOpen = !isOpen">
                    <icon
                        class="toggle-icon"
                        name="eye"
                        size="18" />
                    <span v-if="isOpen">Show Less</span>
                    <span v-else>Show More</span>
                </button>
            </template>
            <template v-else>
                <transition-expand>
                    <div
                        v-if="isOpen"
                        class="styled-card-body-inner">
                        <template v-if="tabs.length">
                            <slot :name="activeTab.slot" />
                        </template>
                        <template v-else>
                            <slot />
                        </template>
                    </div>
                </transition-expand>
            </template>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import TransitionExpand from '@/components/globals/TransitionExpand';
import { debounce, get } from 'lodash';

export default {
    components: {
        Icon,
        TransitionExpand
    },
    props: {
        value: {
            type: Boolean,
            default: true
        },
        initialTab: {
            type: Object,
            validator: tab => tab.hasOwnProperty('name') && tab.hasOwnProperty('slot'),
            default: () => { return { name: null, slot: null } }
        },
        tabs: {
            type: Array,
            validator: prop => prop.every(tab => tab.hasOwnProperty('name') && tab.hasOwnProperty('slot')),
            default: () => []
        },
        tabAlign: {
            type: String,
            default: 'center' // also: left,right
        },
        hideToggle: {
            type: Boolean,
            default: false
        },
        showMore: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: null
        },
        simple: {
            type: Boolean,
            default: false
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        fullHeight: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: true,
            isFluid: true,
            currentTab: ''
        };
    },
    computed: {
        wrapperStyles() {
            if (this.width && !this.isFluid) {
                return {
                    minWidth: this.width + 'px',
                    width: this.width + 'px'
                };
            }

            return {};
        },
        activeTab() {
            return this.currentTab || this.initialTab;
        }
    },
    watch: {
        isOpen(value) {
            this.$emit('input', value);
        },
        value(value) {
            this.isOpen = value;
        },
        currentTab(value) {
            this.$emit('changeTab', value);
        }
    },
    created() {
        this.isOpen = this.value;
    },
    mounted() {
        if (this.width) {
            this.handleWindowSize();
            this.onResize = debounce(() => {
                this.handleWindowSize();
            }, 300);
            window.addEventListener('resize', this.onResize.bind(this));
        }
    },
    destroyed() {
        if (this.width) {
            window.removeEventListener('resize', this.onResize.bind(this));
        }
    },
    methods: {
        handleWindowSize() {
            const clientWidth =
                get(this.$refs, 'card.parentElement.clientWidth', null) || 0;
            if (!clientWidth) {
                return (this.isFluid = true);
            }

            if (clientWidth < this.width) {
                this.isFluid = true;
            } else {
                this.isFluid = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.styled-card {
    box-shadow: 0 0 15px rgba($black, 0.15);
    background-color: $white;
    color: $gray-dark;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @media print {
        box-shadow: none !important;
    }
    &.centered {
        margin: 0 auto;
    }
    &.panel-collapsed {
        .toggle-icon {
            transform: rotate(180deg);
        }
    }
    &.simple {
        box-shadow: none;
        border: 1px solid $gray-light;
    }
    &.full-height {
        .styled-card-body-inner {
            height: 100%;
        }
    }
    .styled-card-header {
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $alabaster-dark;
        height: 70px;
        .styled-card-heading {
            color: $blue-primary;
            padding: 0 1.5rem;
            font-size: 1.2rem;
        }
        .styled-card-actions {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            & > * {
                margin: 0 1.4rem 0 0;
            }
            @media print {
                display: none;
            }
        }
        .styled-card-toggle {
            height: 100%;
            width: 70px;
            border-left: 1px solid $alabaster-dark;
            transition: background-color 0.3s ease-in-out;
            @media print {
                display: none;
            }
            &:hover {
                background-color: rgba($alabaster-dark, 0.5);
            }
            &:focus,
            &:active {
                outline: none;
            }
        }
        .tab-selectors {
            position: absolute;
            top: 52px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            list-style: none;
            padding-left: 0;
            display: flex;
            flex-direction: row;
            &.left {
                left: 25%;
                transform: translateX(-25%);
            }
            &.center {
                left: 50%;
                transform: translateX(-50%);
            }
            &.right {
                left: 75%;
                transform: translateX(-75%);
            }
            .selector {
                display: flex;
                background: #ffffff;
                align-items: center;
                padding: 5px 15px;
                text-decoration: none;
                border: 1px solid #d9d9d9;
                cursor: pointer;
                border-left: none;
                &:first-of-type {
                    border-left: 1px solid #d9d9d9;
                    border-radius: 5px 0 0 5px;
                }
                &:last-of-type {
                    border-radius: 0 5px 5px 0;
                }
                &:first-of-type:last-of-type {
                    border-radius: 5px;
                }
                a {
                    color: #8f9ea6;
                    text-decoration: none;
                }
                &:hover {
                    background: #d9d9d9;
                    border-color: #d9d9d9;
                }

                &.active {
                    border-color: #03a2ea;
                    background: #03a2ea;
                    a {
                        font-weight: 700;
                        color: #ffffff;
                    }
                }
            }
        }
        .toggle-icon {
            transition: transform 0.4s ease-in-out;
        }
    }
    .styled-card-body {
        position: relative;
        flex: 1;
    }
    .show-more {
        transition: max-height 0.5s ease-in-out;
        max-height: 300px;
        position: relative;
        &.open {
            max-height: 1000px;
        }
    }
    .show-more-button {
        position: absolute;
        bottom: 0;
        height: 45px;
        width: 100%;
        color: $gray-text;
        background: $alabaster;

        .toggle-icon {
            margin-right: 5px;
        }
    }
}
</style>
