import { get } from 'lodash';
import {
    PRODUCT_MARKETPLACE,
    PRODUCT_MARKETPLACE_PLUS,
    PRODUCT_ADVERTISING,
    PRODUCT_ADVERTISING_PLUS,
    PRODUCT_BING_MARKETPLACE,
    PRODUCT_TIKTOK_ADS,
    PRODUCT_SNAPCHAT_ADS,
    PRODUCT_PINTEREST_ADS,
    PRODUCT_TIKTOK_ADS_PLUS,
    PRODUCT_SNAPCHAT_ADS_PLUS,
    PRODUCT_PINTEREST_ADS_PLUS,
    PRODUCT_ADVERTISING_LITE,
    FEATURE_INVENTORY,
    FEATURE_TIKTOK_AD_ACCOUNT,
    FEATURE_TIKTOK_PIXEL,
    FEATURE_TIKTOK_CATALOG,
    PRODUCT_TIKTOK_ADS_LITE,
    FEATURE_SNAPCHAT_AD_ACCOUNT,
    FEATURE_SNAPCHAT_PIXEL,
    FEATURE_SNAPCHAT_CATALOG,
    PRODUCT_PINTEREST_ADS_LITE,
    FEATURE_PINTEREST_AD_ACCOUNT,
    FEATURE_PINTEREST_PIXEL,
    FEATURE_PINTEREST_CATALOG,
    FEATURE_WEBSITE_INVENTORY,
    FEATURE_FACEBOOK_MARKETPLACE_PAGE,
    FEATURE_FACEBOOK_AUDIENCE_LOCATION,
    FEATURE_FACEBOOK_PAGE,
    FEATURE_FACEBOOK_AD_ACCOUNT,
    FEATURE_SITE_PROVIDER,
    FEATURE_FACEBOOK_CUSTOM_AUDIENCE_TOS,
    FEATURE_FACEBOOK_LEAD_TOS,
    FEATURE_EVENT_FLOW,
    FEATURE_FACEBOOK_PIXEL,
    FEATURE_FACEBOOK_OFFLINE_EVENT_SET,
    FEATURE_FACEBOOK_LEAD_FORM,
    FEATURE_FACEBOOK_AUTOMOTIVE_CATALOG,
    PRODUCT_SNAPCHAT_ADS_LITE,
    FEATURE_TIKTOK_IDENTITY,
    FEATURE_SNAPCHAT_PUBLIC_PROFILE,
    PRODUCT_GOOGLE_MARKETPLACE,
    FEATURE_GOOGLE_IDENTITY,
    FEATURE_GOOGLE_STORE_CODE,
    FEATURE_GOOGLE_VEHICLE_LISTING_ADS_SETUP,
    PRODUCT_GOOGLE_ADS_PLUS,
    PRODUCT_MICROSOFT_ADS_PLUS,
    PRODUCT_EVENT_FLOW_PLUS,
    FEATURE_EVENT_FLOW_PLUS,
    FEATURE_EVENT_FLOW_PLUS_TAG,
    FEATURE_EVENT_FLOW_PLUS_LEGAL,
    FEATURE_EVENT_FLOW_PLUS_DATA_SHARING,
    FEATURE_MICROSOFT_VEHICLE_LISTING_ADS_SETUP,
    PRODUCT_MICROSOFT_MARKETPLACE_CPC,
    FEATURE_MICROSOFT_MARKETPLACE_CPC_BILLING_AGREEMENT
} from '@/helpers/globals';

export const productData = {
    [PRODUCT_MARKETPLACE]: {
        // Marketplace excluded so OB can complete once core dependencies are met
        requiredFeatures: [
            FEATURE_FACEBOOK_AUDIENCE_LOCATION,
            FEATURE_FACEBOOK_MARKETPLACE_PAGE,
            FEATURE_WEBSITE_INVENTORY,
        ]
    },
    [PRODUCT_MARKETPLACE_PLUS]: {
        // Marketplace excluded so OB can complete once core dependencies are met
        requiredFeatures: [
            FEATURE_FACEBOOK_AUDIENCE_LOCATION,
            FEATURE_FACEBOOK_MARKETPLACE_PAGE,
            FEATURE_INVENTORY,
        ]
    },
    [PRODUCT_ADVERTISING_LITE]: {
        // Required features controlled by playbook
        requiredFeatures: [
            FEATURE_FACEBOOK_PAGE,
            FEATURE_FACEBOOK_AUDIENCE_LOCATION,
            FEATURE_FACEBOOK_AD_ACCOUNT,
            FEATURE_FACEBOOK_CUSTOM_AUDIENCE_TOS,
            FEATURE_SITE_PROVIDER,
            FEATURE_EVENT_FLOW,
            FEATURE_FACEBOOK_PIXEL,
            FEATURE_FACEBOOK_OFFLINE_EVENT_SET,
        ]
    },
    [PRODUCT_ADVERTISING]: {
        // Required features controlled by playbook
        requiredFeatures: [
            FEATURE_FACEBOOK_PAGE,
            FEATURE_FACEBOOK_AUDIENCE_LOCATION,
            FEATURE_FACEBOOK_AD_ACCOUNT,
            FEATURE_FACEBOOK_CUSTOM_AUDIENCE_TOS,
            FEATURE_SITE_PROVIDER,
            FEATURE_EVENT_FLOW,
            FEATURE_FACEBOOK_PIXEL,
            FEATURE_FACEBOOK_OFFLINE_EVENT_SET,
        ]
    },
    [PRODUCT_ADVERTISING_PLUS]: {
        // Required features controlled by playbook
        requiredFeatures: [
            FEATURE_FACEBOOK_PAGE,
            FEATURE_FACEBOOK_AUDIENCE_LOCATION,
            FEATURE_FACEBOOK_AD_ACCOUNT,
            FEATURE_FACEBOOK_CUSTOM_AUDIENCE_TOS,
            FEATURE_FACEBOOK_LEAD_TOS,
            FEATURE_SITE_PROVIDER,
            FEATURE_INVENTORY,
            FEATURE_EVENT_FLOW,
            FEATURE_FACEBOOK_PIXEL,
            FEATURE_FACEBOOK_OFFLINE_EVENT_SET,
            FEATURE_FACEBOOK_LEAD_FORM,
            FEATURE_FACEBOOK_AUTOMOTIVE_CATALOG,
        ]
    },
    [PRODUCT_BING_MARKETPLACE]: {
        requiredFeatures: [
            FEATURE_INVENTORY
        ]
    },
    [PRODUCT_MICROSOFT_ADS_PLUS]: {
        requiredFeatures: [
            FEATURE_INVENTORY,
            FEATURE_SITE_PROVIDER,
            FEATURE_EVENT_FLOW,
            FEATURE_MICROSOFT_VEHICLE_LISTING_ADS_SETUP
        ]
    },
    [PRODUCT_MICROSOFT_MARKETPLACE_CPC]: {
        requiredFeatures: [
            FEATURE_INVENTORY,
            FEATURE_MICROSOFT_MARKETPLACE_CPC_BILLING_AGREEMENT
        ]
    },
    [PRODUCT_TIKTOK_ADS_LITE]: {
        requiredFeatures: [
            FEATURE_TIKTOK_IDENTITY,
            FEATURE_TIKTOK_AD_ACCOUNT,
            FEATURE_TIKTOK_PIXEL,
        ]
    },
    [PRODUCT_TIKTOK_ADS]: {
        requiredFeatures: [
            FEATURE_EVENT_FLOW,
            FEATURE_TIKTOK_IDENTITY,
            FEATURE_TIKTOK_AD_ACCOUNT,
            FEATURE_TIKTOK_PIXEL,
        ]
    },
    [PRODUCT_TIKTOK_ADS_PLUS]: {
        requiredFeatures: [
            FEATURE_INVENTORY,
            FEATURE_TIKTOK_IDENTITY,
            FEATURE_TIKTOK_AD_ACCOUNT,
            FEATURE_TIKTOK_PIXEL,
            FEATURE_TIKTOK_CATALOG,
        ]
    },
    [PRODUCT_SNAPCHAT_ADS_LITE]: {
        requiredFeatures: [
            FEATURE_SNAPCHAT_PUBLIC_PROFILE,
            FEATURE_SNAPCHAT_AD_ACCOUNT,
            FEATURE_SNAPCHAT_PIXEL,
        ]
    },
    [PRODUCT_SNAPCHAT_ADS]: {
        requiredFeatures: [
            FEATURE_EVENT_FLOW,
            FEATURE_SNAPCHAT_PUBLIC_PROFILE,
            FEATURE_SNAPCHAT_AD_ACCOUNT,
            FEATURE_SNAPCHAT_PIXEL,
        ]
    },
    [PRODUCT_SNAPCHAT_ADS_PLUS]: {
        requiredFeatures: [
            FEATURE_INVENTORY,
            FEATURE_SNAPCHAT_PUBLIC_PROFILE,
            FEATURE_SNAPCHAT_AD_ACCOUNT,
            FEATURE_SNAPCHAT_PIXEL,
            FEATURE_SNAPCHAT_CATALOG
        ]
    },
    [PRODUCT_PINTEREST_ADS_LITE]: {
        requiredFeatures: [
            FEATURE_PINTEREST_AD_ACCOUNT,
            FEATURE_PINTEREST_PIXEL,
        ]
    },
    [PRODUCT_PINTEREST_ADS]: {
        requiredFeatures: [
            FEATURE_EVENT_FLOW,
            FEATURE_PINTEREST_AD_ACCOUNT,
            FEATURE_PINTEREST_PIXEL,
        ]
    },
    [PRODUCT_PINTEREST_ADS_PLUS]: {
        requiredFeatures: [
            FEATURE_INVENTORY,
            FEATURE_PINTEREST_AD_ACCOUNT,
            FEATURE_PINTEREST_PIXEL,
            FEATURE_PINTEREST_CATALOG
        ]
    },
    [PRODUCT_GOOGLE_MARKETPLACE]: {
        requiredFeatures: [
            FEATURE_INVENTORY,
            FEATURE_GOOGLE_IDENTITY
        ]
    },
    [PRODUCT_GOOGLE_ADS_PLUS]: {
        requiredFeatures: [
            FEATURE_INVENTORY,
            FEATURE_SITE_PROVIDER,
            FEATURE_EVENT_FLOW,
            FEATURE_GOOGLE_IDENTITY,
            FEATURE_GOOGLE_STORE_CODE,
            FEATURE_GOOGLE_VEHICLE_LISTING_ADS_SETUP,
        ]
    },
    [PRODUCT_EVENT_FLOW_PLUS]: {
        requiredFeatures: [
            FEATURE_EVENT_FLOW_PLUS,
            FEATURE_EVENT_FLOW_PLUS_TAG,
            FEATURE_EVENT_FLOW_PLUS_LEGAL,
            FEATURE_EVENT_FLOW_PLUS_DATA_SHARING,
        ]
    },
};

export default {
    onboardingIneligibleProductConflict: (state, getters, rootState, rootGetters) => (ineligibleProductId) => {
        // Find the product which contains the ineligible product
        const conflictingProductUpgradeConfig = state.productUpgradePaths.find(upgradeProductConfig => {
            return (
                upgradeProductConfig.upgradeFrom.includes(ineligibleProductId) &&
                rootGetters.dealerProductIds.includes(upgradeProductConfig.id)
            );
        });

        // If it's found return the full product config for it
        if (conflictingProductUpgradeConfig) {
            return state.products.find(product => product.id === conflictingProductUpgradeConfig.id) || {};
        }

        return null;
    },
    currentStepActiveFeatures(state, getters) {
        const dependencies = getters.currentSteps?.[state.currentStep]?.dependencies || [];

        return dependencies.filter(dependency => {
            return getters.applicableFeatures.includes(dependency);
        });
    },
    onboardingIneligibleProducts(state, getters, rootState, rootGetters) {
        let ineligibleProducts = [];

        state.productUpgradePaths
            // Target the products that the dealer has
            .filter(productConfig => rootGetters.dealerProductIds.includes(productConfig.id))
            // Collect all of the "upgradeFrom" products and consider them ineligible
            .forEach(productConfig => {
                ineligibleProducts = ineligibleProducts.concat(productConfig.upgradeFrom);
            });

        return [...new Set(ineligibleProducts)];
    },
    onboardingEligibleProducts(state, getters) {
        // Filter out ineligible products and return an array of product IDs
        return state.products
                        .filter(product => !getters.onboardingIneligibleProducts.includes(product.id))
                        .map(product => product.id);

    },
    onboardingProductToRemove(state, getters, rootState, rootGetters) {
        const upgradeConfig = state.productUpgradePaths.find(product => {
            return state.productIds.includes(product.id);
        });

        if (!upgradeConfig) {
            return null;
        }
        // Return the first dealer product that's in the upgrade
        // from this since we'll need to remove it during this step
        return rootGetters.dealerProducts.find(product => {
            return upgradeConfig.upgradeFrom.includes(product.id);
        }) || null;
    },
    onboardingKey(state, getters, rootState) {
        return `onboarding:${rootState.dealer.currentDealerId}:${state.type}:${state.playId || state.productIds.join('-')}`;
    },
    onboardingStorageData(state) {
        return {
            existingFeatures: state.existingFeatures,
            currentStep: state.currentStep,
            latestStep: state.latestStep,
            redirectUrl: state.redirectUrl,
            data: state.data
        };
    },
    onboardingStepByFeature: (state, getters) => (feature) => {
        // First try to find incomplete matching step
        const incompleteStep = getters.currentSteps.findIndex(step => {
            if (!step.dependencies) {
                return false;
            }

            return step.dependencies.includes(feature) && !step.complete;
        });
        if (incompleteStep !== -1) {
            return incompleteStep;
        }

        // Otherwise return the first matching step
        return getters.currentSteps.findIndex(step => {
            if (!step.dependencies) {
                return false;
            }

            return step.dependencies.includes(feature);
        });
    },
    dealerHasOnboardingProduct(state, getters, rootState, rootGetters) {
        if (!state?.productIds?.length) {
            return false;
        }

        return rootGetters.dealerHasProducts(state.productIds);
    },
    onboardingValid(state, getters) {
        if (state.type === 'product') {
            return getters.requiredFeaturesValid;
        }

        if (state.type === 'play') {
            return getters.requiredFeaturesValid && getters.userDataValid;
        }

        return false;
    },
    onboardingUserCampaigns(state) {
        return get(state, 'data.campaigns', null) || [];
    },
    onboardingUserCampaignsValid(state, getters) {
        if (!state.playCampaigns.length) {
            return false;
        }

        return state.playCampaigns.every(campaign => {
            const userCampaign = getters.onboardingUserCampaigns
                .find(userCampaign => userCampaign.remote_name === campaign.remote_name);

            // If there's no defined user campaign it's not valid
            if (!userCampaign) {
                return false;
            }

            return userCampaign.budget >= campaign.minimum_budget;
        });
    },
    onboardingUserAdSets(state) {
        return get(state, 'data.adSets', null) || [];
    },
    onboardingUserAdSetsValid(state, getters) {
        if (!state.playAdSets.length) {
            return false;
        }

        return state.playAdSets.every(adSet => {
            const userAdSet = getters.onboardingUserAdSets
                .find(userAdSet => userAdSet.remote_name === adSet.remote_name);

            // If there's no defined user campaign it's not valid
            if (!userAdSet) {
                return false;
            }

            return userAdSet.radius >= adSet.minimum_radius;
        });
    },
    onboardingUserAdCreatives(state) {
        return get(state, 'data.adCreatives', null) || [];
    },
    onboardingUserAdCreativesValid(state, getters) {
        // If there's no creatives the step doesn't display
        // so we can consider them valid!
        if (!state.playAdCreatives.length) {
            return true;
        }

        return state.playAdCreatives.every(adCreative => {
            const userAdCreative = getters.onboardingUserAdCreatives
                .find(userAdCreative => userAdCreative.remote_name === adCreative.remote_name);

            // If there's no defined user campaign it's not valid
            if (!userAdCreative) {
                return false;
            }

            return true;
        });
    },
    dailyBudgetTotal(state, getters) {
        return getters.onboardingUserCampaigns
            .map(campaign => {
                return campaign.budget || 0;
            })
            .reduce((a, b) => {
                return a + b;
            }, 0);
    },
    monthlyBudgetTotal(state, getters) {
        return getters.dailyBudgetTotal * 30;
    },
    userDataValid(state, getters) {
        return getters.onboardingUserCampaignsValid && getters.onboardingUserAdSetsValid && getters.onboardingUserAdCreativesValid;
    },
    dependentPlayFeatures(state) {
        return get(state.play, 'dependencies', null) || [];
    },
    playStatus(state, getters, rootState) {
        // Derive the play status from the product status
        return rootState.dealer.featureStatus
                    .filter(feature => {
                        // Filter out features that aren't dependent for the play
                        if (!getters.requiredPlayFeatures.includes(feature.feature)) {
                            return false;
                        }

                        // Include the rest
                        return true;
                    });
    },
    incompleteFeatureStatus(state, getters, rootState) {
        const status = (state.type === 'play') ? getters.playStatus : rootState.dealer.featureStatus;

        return status
                .filter(feature => feature.status !== 'complete')
                .map(feature => {
                    let errors = [];

                    // Loop through all errors and concatenate them
                    // we do this because the errors are an array with an
                    // array of errors inside them
                    feature.errors.forEach(error => {
                        errors = [
                            ...errors,
                            ...error.errors
                        ];
                    });

                    // Lay the new errors down over the old ones
                    return {
                        ...feature,
                        ...{ errors }
                    };
                });
    },
    requiredPlayFeatures(state, getters) {
        return getters.dependentPlayFeatures;
    },
    completePlayFeatures(state, getters) {
        return getters.playStatus
                    .filter(feature => feature.status === 'complete')
                    .map(feature => feature.feature);
    },
    incompletePlayFeatures(state, getters) {
        return getters.playStatus
                    .filter(feature => feature.status !== 'complete')
                    .map(feature => feature.feature);
    },
    playFeaturesValid(state, getters) {
        if (!getters.playStatus.length) {
            return false;
        }

        return getters.playStatus.every(feature => feature.status === 'complete');
    },
    outstandingPlayFeatures(state, getters, rootState, rootGetters) {
        return rootGetters.requiredPlayFeatures;
    },
    requiredProductFeatures(state, getters, rootState, rootGetters) {

        const reducer = (currentFeatures, productId) => {

            if (!productData[productId]) {
                return currentFeatures;
            }

            // Append the required features to the current features
            const { requiredFeatures } = productData[productId];
            return currentFeatures.concat(requiredFeatures);
        };

        const requiredFeatures = state.productIds.reduce(reducer, []);

        // If the dealer has the inventory feature drop website inventory
        if (rootGetters.dealerFeatures.includes('inventory')) {
            const websiteInventoryFeature = requiredFeatures.indexOf('website_inventory');
            if (websiteInventoryFeature !== -1) {
                requiredFeatures.splice(websiteInventoryFeature, 1);
            }
        }

        // Return the unique result set
        return [ ...new Set(requiredFeatures) ];
    },
    requiredFeatures(state, getters, rootState, rootGetters) {

        let requiredFeatures = [...getters.requiredProductFeatures];

        // If this is a play onboarding combine the product
        // features with the play features
        if (state.type === 'play') {
            requiredFeatures = [
                ...new Set([
                    ...requiredFeatures,
                    ...getters.requiredPlayFeatures
                ])
            ];
        }


        // If the dealer has the inventory feature drop website inventory
        // We have to do this switch for combined play/product onboardings
        if (rootGetters.dealerFeatures.includes('inventory')) {
            const websiteInventoryFeature = requiredFeatures.indexOf('website_inventory');
            if (websiteInventoryFeature !== -1) {
                requiredFeatures.splice(websiteInventoryFeature, 1);
            }
        }

        return requiredFeatures;
    },
    applicableFeatures(state, getters, rootState, rootGetters) {
        if (state.type === 'product') {
            return rootGetters.dealerFeaturesByProducts(state.productIds);
        }

        if (state.type === 'play') {
            // @todo re-evaluate how optional features work - for now
            // do this to show all the steps
            return [ ...new Set([
                ...getters.outstandingPlayFeatures,
                ...rootGetters.dealerFeaturesByProducts(state.productIds)
            ]) ];
        }

        return [];
    },
    optionalFeatures(state, getters) {
        // @todo add product when available

        // Features that exist in the product that aren't in the play
        if (state.type === 'play') {
            return getters.requiredFeatures.filter(feature => {
                return !getters.requiredPlayFeatures.includes(feature);
            });
        }

        return [];
    },
    incompleteFeatures(state, getters, rootState) {
        return rootState.dealer.featureStatus
                    .filter(feature => feature.status !== 'complete')
                    .map(feature => feature.feature);
    },
    requiredFeaturesValid(state, getters, rootState) {
        if (!rootState.dealer.featureStatus.length) {
            return false;
        }

        return rootState.dealer.featureStatus
                    .filter(feature => getters.requiredFeatures.includes(feature.feature))
                    .every(feature => feature.status === 'complete');
    },
    featuresValid(state, getters, rootState) {
        if (!rootState.dealer.featureStatus.length) {
            return false;
        }

        return rootState.dealer.featureStatus.every(feature => feature.status === 'complete');
    },
    onboardingStatus(state, getters, rootState) {
        return rootState.dealer.featureStatus;
    },
    firstIncompleteStep(state, getters) {
        const step = getters.currentSteps.findIndex(step => !step.complete);
        return step === -1 ? null : step;
    },
    nextStep(state, getters) {
        // If we're at the end return null
        if (state.currentStep === (getters.currentSteps.length - 1)) {
            return null;
        }

        // Break on the incomplete step we find moving forwards
        for (let i = state.currentStep + 1; i < getters.currentSteps.length; i++) {
            if (!getters.currentSteps[i].complete) {
                return i;
            }
        }

        // Fall back to null
        return null;
    },
    previousStep(state) {
        // If we're at the begining return null
        if (state.currentStep === 0) {
            return null;
        }

        return state.currentStep - 1;
    },
    currentSteps(state, getters, rootState, rootGetters) {
        // Function to be mapped onto each step to compute
        // additional properties on the step used in the
        // multi-step component (generally for display & navigation)
        const computeStepProperties = (step) => {

            // Determine if the step is required
            step.required = step.dependencies
                .some(dependency => getters.requiredFeatures.includes(dependency));

            // If a completion function has been defined for the step
            // call it and set it's result to the complete property
            if (typeof step.isComplete === 'function') {
                step.complete = step.isComplete.call(null, {
                    step,
                    data: state.data,
                    applicableFeatures: getters.applicableFeatures,
                    onboardingStatus: getters.onboardingStatus,
                    requiredFeatures: getters.requiredFeatures,
                    incompleteFeatures: getters.incompleteFeatures,
                    onboardingUserCampaignsValid: getters.onboardingUserCampaignsValid,
                    onboardingUserAdSetsValid: getters.onboardingUserAdSetsValid,
                    onboardingUserAdCreativesValid: getters.onboardingUserAdCreativesValid,
                    dealerHasOnboardingProduct: getters.dealerHasOnboardingProduct,
                    dealerInventoryType: rootGetters.dealerInventoryType
                });
            }

            return step;
        };

        // Calls the step's is active function to determine if it
        // should be active in the current context
        const stepIsActive = (step) => {
            return step.isActive.call(null, {
                step,
                type: state.type,
                data: state.data,
                existingFeatures: state.existingFeatures,
                requiredFeatures: getters.requiredFeatures,
                applicableFeatures: getters.applicableFeatures,
                dealerHasOnboardingProduct: getters.dealerHasOnboardingProduct,
                currentDealer: rootState.dealer.currentDealer,
                playAdCreatives: state.playAdCreatives,
            });
        };

        return state.steps.filter(stepIsActive).map(computeStepProperties);
    },
    activeOnboardingsCount(state, getters) {
        return getters.activeOnboardings.length;
    },
    formatOnboarding: () => (onboarding) => {
        onboarding.type = (onboarding.play_id === null) ? 'product' : 'play';
        onboarding.label = (onboarding.type === 'play') ? (onboarding.play?.display_name || '') : (onboarding?.products?.data.map(product => product.display_name).join(' & ') || '');

        // Cache the updated date for sorting
        onboarding.updatedAt = new Date(onboarding.updated_at.date);

        return onboarding;
    },
    activeDealerOnboardings(state, getters, rootState) {
        const dealerOnboardings = get(rootState.dealer.currentDealer, 'dealer_product_onboarding.data', null) || [];
        return dealerOnboardings
                    .map(getters.formatOnboarding)
                    .sort((a, b) => b.updatedAt - a.updatedAt);
    },
    activeOnboardings(state, getters) {
        return state.onboardings
                    .filter(onboarding => onboarding.dealer !== null) // Fix for deleted dealers
                    .map(getters.formatOnboarding)
                    .sort((a, b) => b.updatedAt - a.updatedAt);
    },
};
