import types from '@/store/mutation-types';

export default {
    [types.SET_ONBOARDING_INITIALIZING](state, isInitializing) {
        state.initializing = isInitializing;
    },
    [types.SET_ONBOARDING_EXISTING_FEATURES](state, existingFeatures) {
        state.existingFeatures = existingFeatures;
    },
    [types.SET_ONBOARDING_DATA](state, data) {
        state.data = data;
    },
    [types.UPDATE_ONBOARDING_DATA](state, data) {
        // Merge the new data with the existing data
        state.data = { ...state.data, ...data };
    },
    [types.SET_ONBOARDING_ID](state, id) {
        state.onboardingId = id;
    },
    [types.SET_ONBOARDING_TYPE](state, type) {
        state.type = type;
    },
    [types.SET_ONBOARDING_ERROR](state, error) {
        state.error = error;
    },
    [types.SET_ONBOARDING_PRODUCTS](state, products) {
        state.productIds = products.map(product => product.id);
        state.products = products;
    },
    [types.SET_ONBOARDING_PRODUCT_IDS](state, productIds) {
        state.productIds = productIds;
    },
    [types.SET_ONBOARDING_PLAY](state, play) {
        if (play === null) {
            state.playId =  null;
            state.play = null;
        } else {
            state.playId = play.id;
            state.play = play;
        }
    },
    [types.SET_ONBOARDING_PLAY_ID](state, playId) {
        state.playId = playId;
    },
    [types.SET_ONBOARDING_PLAY_LOADING](state, isLoading) {
        state.playLoading = isLoading;
    },
    [types.SET_ONBOARDING_PLAY_CAMPAIGNS](state, { campaigns, adSets, adCreatives }) {
        state.playCampaigns = campaigns;
        state.playAdSets = adSets;
        state.playAdCreatives = adCreatives;
    },
    [types.SET_ONBOARDING_PLAY_CAMPAIGNS_LOADING](state, isLoading) {
        state.playCampaignsLoading = isLoading;
    },
    [types.SET_ONBOARDING_CURRENT_STEP](state, step) {
        state.currentStep = step;
        if (state.latestStep < step) {
            state.latestStep = step;
        }
        // Tag an update
        state.updatedAt = new Date();
    },
    [types.SET_ONBOARDING_LATEST_STEP](state, step) {
        state.latestStep = step;
    },
    [types.SET_ONBOARDING_NEXT_STEP](state, step) {
        state.nextStep = step;
    },
    [types.SET_ONBOARDING_PREVIOUS_STEP](state, step) {
        state.previousStep = step;
    },
    [types.TOGGLE_ONBOARDING_SKIP_DISCLAIMER](state, isOpen) {
        state.skipDisclaimerOpen = isOpen;
    },
    [types.SET_ONBOARDING_STATUS_LOADING](state, isLoading) {
        state.statusLoading = isLoading;
    },
    [types.SET_ONBOARDINGS](state, onboardings) {
        state.onboardings = onboardings;
    },
    [types.SET_ONBOARDINGS_LOADING](state, isLoading) {
        state.onboardingsLoading = isLoading;
    },
    [types.SET_ONBOARDINGS_UPDATED_AT](state, date) {
        state.onboardingsUpdatedAt = date;
    },
    [types.SET_ONBOARDING_REDIRECT_URL](state, redirectUrl) {
        state.redirectUrl = redirectUrl;
    }
};
