/**
 *   This file contains all the routes and components
 *   called by each route. It also has the auth level
 *   for each route.
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import { get } from 'lodash';
import store from './store';
import {
    INTERFACE_FULL,
    INTERFACE_MINIMAL,
    INTERFACE_BLANK,
    FEATURE_LEAD_DESTINATION,
    FEATURE_PLAYBOOK,
    FEATURE_INVENTORY,
    FEATURE_FACEBOOK_AD_ACCOUNT,
    //FEATURE_FACEBOOK_MARKETPLACE,
    VERTICAL_AUTOMOTIVE,
    FEATURE_SNAPCHAT_AD_ACCOUNT,
    FEATURE_TIKTOK_AD_ACCOUNT,
    FEATURE_TIKTOK_IDENTITY,
    FEATURE_SNAPCHAT_PUBLIC_PROFILE,
    FEATURE_PINTEREST_AD_ACCOUNT,
} from './helpers/globals';
import { loadRecaptchaScript, removeRecaptchaScript } from '@/helpers/manageRecaptcha';

Vue.use(VueRouter);

//All the Routes and the auth levels for each route
let router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    mode: 'history',
    routes: [
        {
            path: '/resellers',
            redirect: { path: '/organizations' },
        },
        {
            path: '/organizations',
            name: 'resellers',
            component: () => import('./components/agencies/Agencies'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/agencies/:id',
            redirect: { path: '/organizations/:id' },
        },
        {
            path: '/organizations/:id(\\d+)',
            name: 'agency',
            component: () => import('./components/agencies/AgencyID'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/agency/new',
            redirect: { path: '/organizations/new' },
        },
        {
            path: '/organizations/new',
            name: 'agency-new',
            component: () => import('./components/agencies/AgencyAdd'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/dealers',
            redirect: { path: '/accounts' },
        },
        {
            path: '/accounts',
            name: 'dealers',
            component: () => import('./components/dealers/Dealers'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/account-management/:tab',
            name: 'account-management',
            component: () => import('./components/account-management/AccountManagement'),
            props: (route) => ({
                tab: route.params.tab
            }),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ]
            }
        },
        {
            path: '/dealers/:dealer_id(\\d+)',
            redirect: { name: 'global-dashboard' },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/dashboard',
            redirect: { path: '/accounts/:dealer_id(\\d+)/dashboard' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/dashboard',
            name: 'global-dashboard',
            component: () => import('./components/dealers/dashboards/global/Dashboard'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'onboarding',
                    'client',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/dashboards/facebook',
            redirect: { path: '/accounts/:dealer_id(\\d+)/dashboards/facebook' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/dashboards/facebook',
            name: 'facebook-dashboard',
            component: () => import('./components/dealers/dashboards/facebook/Dashboard'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'onboarding',
                    'client',
                    'agency_dashboard',
                ],
            },
        },
        //{
        //    path: '/dealers/:dealer_id(\\d+)/tools/facebook-marketplace',
        //    redirect: { path: '/accounts/:dealer_id(\\d+)/tools/facebook-marketplace' },
        //},
        //{
        //    path: '/accounts/:dealer_id(\\d+)/tools/facebook-marketplace',
        //    name: 'facebook-marketplace',
        //    component: () => import('./components/dealers/tools/DealerMarketplacePosting'),
        //    meta: {
        //        betaDisclaimer: false,
        //        requiresAuth: true,
        //        interfaceType: INTERFACE_FULL,
        //       roles: [
        //            'super_admin',
        //            'admin',
        //            'performance_manager',
        //            'onboarding',
        //            'client',
        //            'agency_dashboard'
        //        ],
        //        features: [
        //            FEATURE_FACEBOOK_MARKETPLACE
        //        ],
        //    }
        //},
        {
            path: '/dealers/:dealer_id(\\d+)/dashboards/microsoft',
            redirect: { path: '/accounts/:dealer_id(\\d+)/dashboards/microsoft' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/dashboards/microsoft',
            name: 'microsoft-dashboard',
            component: () => import('./components/dealers/dashboards/microsoft/Dashboard'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'onboarding',
                    'client',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/settings',
            redirect: { path: '/accounts/:dealer_id(\\d+)/settings' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/settings',
            name: 'dealer',
            component: () => import('./components/dealers/settings/DealerSettings'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'onboarding',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/agencies/:agency_id/communication-central',
            redirect: { path: '/organizations/:agency_id/communication-central' },
        },
        {
            path: '/organizations/:agency_id/communication-central',
            name: 'communication-central',
            component: () => import('./components/globals/CommunicationCentral'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ]
            }
        },
        {
            path: '/dealer/new',
            redirect: { path: '/accounts/new' },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/edit',
            redirect: { path: '/accounts/:dealer_id(\\d+)/edit' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/edit',
            name: 'dealer-edit',
            component: () => import('./components/dealers/DealerEdit'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                ],
            },
        },
        {
            path: '/upgrade-dealer',
            name: 'upgrade-dealer',
            component: () => import('./components/dealers/UpgradeDealer'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/onboarding/:onboarding_id(\\d+)',
            redirect: { path: '/accounts/:dealer_id(\\d+)/onboarding/:onboarding_id(\\d+)' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/onboarding/:onboarding_id(\\d+)',
            name: 'dealer-onboarding',
            component: () => import('./components/onboarding/workflow'),
            props: (route) => ({
                onboardingId: parseInt(route.params.onboarding_id),
                stepKey: route.query.step || null,
            }),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_MINIMAL,
                hideHelpWidget: true,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/account/new',
            redirect: { path: '/accounts/new' },
        },
        {
            path: '/accounts/new',
            name: 'account-new',
            component: () => import('./components/onboarding/AccountAdd'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_MINIMAL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/account/onboarding-status',
            redirect: { path: '/accounts/onboarding-status' },
        },
        {
            path: '/accounts/onboarding-status',
            name: 'account-onboarding-status',
            component: () => import('./components/onboarding/AccountOnboardingStatus'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/extract-campaigns',
            name: 'extract-campaigns',
            component: () => import('./components/dealers/ExtractCampaigns'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/build-snap-ads',
            name: 'build-snap-ads',
            component: () => import('./components/dealers/BuildSnapInventory/index'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/setup-tag-manager',
            name: 'setup-tag-manager',
            component: () => import('./components/products/SetupTagManager'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/gtm-to-event-flow',
            name: 'gtm-to-event-flow',
            component: () => import('./components/products/TagManagerToEventFlow'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/leads',
            redirect: { path: '/accounts/:dealer_id(\\d+)/leads' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/leads',
            name: 'leads-all',
            component: () => import('./components/leads/Leads'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                    'client',
                ],
                features: [
                    FEATURE_LEAD_DESTINATION,
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/leads',
            redirect: { path: '/accounts/:dealer_id(\\d+)/leads' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/deploy-facebook-play',
            name: 'deploy-facebook-play',
            component: () => import('./components/dealers/DeployFacebookPlay'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                ],
            },
        },
        {
            path: '/users/all',
            name: 'users-all',
            component: () => import('./components/users/UsersCustomTable'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/get-sessions-access',
            name: 'get-session-access',
            component: () => import('./components/sessions-reports/SessionsAccessControl'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                verifyIfHasSessionAccess: true,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/sessions/reports',
            name: 'sessions',
            component: () => import('./components/sessions-reports/Sessions'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                requireSessionPermission: true,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/users/settings',
            name: 'user-settings',
            component: () => import('./components/users/UserSettings'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'onboarding',
                    'client',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/user/:id',
            name: 'user',
            component: () => import('./components/users/UserID'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                ],
            },
        },
        {
            path: '/users/new',
            name: 'users-new',
            component: () => import('./components/users/UserNew'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./components/auth/login'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            },
            props: true,
        },
        {
            path: '/password_reset',
            name: 'password-reset',
            component: () => import('./components/auth/PasswordReset'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
                useRecaptcha: true
            },
            beforeEnter: (to, from, next) => {
                store.dispatch('logout');
                next();
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('./components/auth/ForgotPassword'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            },
        },
        {
            path: '/twofa-auth',
            name: 'twofa-auth',
            component: () => import('./components/auth/TwoFAAuth.vue'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            }
        },
        {
            path: '/twofa-challenge',
            name: 'twofa-challenge',
            component: () => import('./components/auth/TwoFAChallenge.vue'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            redirect: () => {
                return store.state.user.isLoggedIn ? store.getters.userHomepage : { name: 'login' };
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/inventory',
            redirect: { path: '/accounts/:dealer_id(\\d+)/inventory' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/inventory',
            name: 'inventory',
            component: () => import('./components/inventory-dashboard/InventoryDashboard'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                    'client',
                ],
                verticals: [
                    VERTICAL_AUTOMOTIVE,
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/inventory/:id',
            redirect: { path: '/accounts/:dealer_id(\\d+)/inventory/:id' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/inventory/:id',
            name: 'inventory-id',
            component: () => import('./components/inventory/VehicleDetail'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                    'client',
                ],
            },
            props: true,
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('./components/auth/Settings'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                    'client',
                ],
            },
        },
        {
            path: '/onboard-dealer',
            name: 'onboard-dealer',
            redirect: { name: 'account-new' },
        },
        {
            path: '/onboard-dealer-legacy',
            redirect: { name: 'account-new' },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/reports/vehicle-performance',
            redirect: { path: '/accounts/:dealer_id(\\d+)/reports/vehicle-performance' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/reports/vehicle-performance',
            name: 'reports-vehicle-performance',
            component: () => import('./components/reports/VehiclePerformance'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'client',
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
                features: [
                    FEATURE_INVENTORY,
                    FEATURE_FACEBOOK_AD_ACCOUNT,
                ],
                verticals: [
                    VERTICAL_AUTOMOTIVE,
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/reports/facebook-advertising',
            redirect: { path: '/accounts/:dealer_id(\\d+)/reports/facebook-advertising' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/reports/facebook-advertising',
            name: 'reports-facebook-advertising',
            component: () => import('./components/reports/facebook-advertising/FacebookAdvertising'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'client',
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/dashboards/snapchat',
            redirect: { path: '/accounts/:dealer_id(\\d+)/dashboards/snapchat' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/dashboards/snapchat',
            name: 'snapchat-dashboard',
            component: () => import('./components/dealers/dashboards/snapchat/Dashboard.vue'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'client',
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/dashboards/tiktok',
            redirect: { path: '/accounts/:dealer_id(\\d+)/dashboards/tiktok' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/dashboards/tiktok',
            name: 'tiktok-dashboard',
            component: () => import('./components/dealers/dashboards/tiktok/Dashboard.vue'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'client',
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/dashboards/pinterest',
            redirect: { path: '/accounts/:dealer_id(\\d+)/dashboards/pinterest' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/dashboards/pinterest',
            name: 'pinterest-dashboard',
            component: () => import('./components/dealers/dashboards/pinterest/Dashboard.vue'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'client',
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/meeting-request',
            name: 'meeting-request',
            component: () => import('./components/meeting-request/MeetingRequest'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'client',
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                ],
            },
        },
        {
            path: '/resellers/:agency_id/billing',
            redirect: { path: '/organizations/:agency_id/billing' },
        },
        {
            path: '/organizations/:agency_id/billing',
            name: 'resellers-billing',
            component: () => import('./components/resellers/AgencyBilling'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ]
            }
        },
        {
            path: '/resellers/:agency_id/reports/facebook-advertising',
            redirect: { path: '/organizations/:agency_id/reports/facebook-advertising' },
        },
        {
            path: '/organizations/:agency_id/reports/facebook-advertising',
            name: 'resellers-reports-facebook-advertising',
            component: () => import('./components/resellers/facebook-advertising/FacebookAdvertising'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ]
            }
        },
        {
            path: '/resellers/:agency_id/reports/facebook-offline-events',
            redirect: { path: '/organizations/:agency_id/reports/facebook-offline-events' },
        },
        {
            path: '/organizations/:agency_id/reports/facebook-offline-events',
            name: 'resellers-reports-facebook-offline-events',
            component: () => import('./components/resellers/FacebookOfflineEvents'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ]
            }
        },
        {
            path: '/resellers/:agency_id/reports/:platform/advertising',
            redirect: { path: '/organizations/:agency_id/reports/:platform/advertising' },
        },
        {
            path: '/organizations/:agency_id/reports/:platform/advertising',
            name: 'resellers-reports-channel-advertising',
            component: () => import('./components/resellers/channel-advertising/ChannelAdvertising'),
            props: (route) => ({
                platform: route.params.platform,
            }),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ],
            },
        },
        {
            path: '/resellers/:agency_id/reports/:platform/budgeting',
            redirect: { path: '/organizations/:agency_id/reports/:platform/budgeting' },
        },
        {
            path: '/organizations/:agency_id/reports/:platform/budgeting',
            name: 'resellers-reports-channel-budgeting',
            component: () => import('./components/resellers/channel-budgeting/ChannelBudgeting'),
            props: (route) => ({
                platform: route.params.platform,
            }),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ]
            }
        },
        {
            path: '/resellers/:agency_id/reports/facebook-budgeting',
            redirect: { path: '/organizations/:agency_id/reports/facebook/budgeting' },
        },
        {
            path: '/organizations/:agency_id/reports/facebook-budgeting',
            redirect: { path: '/organizations/:agency_id/reports/facebook/budgeting' },
        },
        {
            path: '/resellers/:agency_id/reports/playbooks',
            redirect: { path: '/organizations/:agency_id/reports/playbooks' },
        },
        {
            path: '/organizations/:agency_id/reports/playbooks',
            name: 'resellers-reports-playbooks',
            component: () => import('./components/reports/ResellerPlaybooks'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'agency_dashboard',
                    'performance_manager',
                    'admin',
                ],
                agencies: [
                    100, // Social Hub
                ],
            },
        },
        //{
        //    path: '/resellers/:agency_id/tools/facebook-marketplace',
        //    redirect: { path: '/organizations/:agency_id/tools/facebook-marketplace' },
        //},
        //{
        //    path: '/organizations/:agency_id/tools/facebook-marketplace',
        //    name: 'resellers-tools-facebook-marketplace',
        //    component: () => import('./components/resellers/tools/ResellerMarketplacePosting'),
        //    meta: {
        //        requiresAuth: true,
        //        interfaceType: INTERFACE_FULL,
        //        roles: [
        //            'super_admin',
        //            'admin',
        //            'performance_manager',
        //            'agency_dashboard'
        //        ]
        //    }
        //},
        {
            path: '/resellers/invites',
            redirect: { path: '/organizations/invites' },
        },
        {
            path: '/organizations/invites',
            name: 'resellers-invites',
            component: () => import('@/components/resellers/onboarding/Invites'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                ],
            },
        },
        {
            path: '/resellers/settings',
            redirect: { path: '/organizations/settings' },
        },
        {
            path: '/organizations/settings',
            name: 'reseller-settings',
            component: () => import('./components/resellers/settings/Settings'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/sign-up',
            name: 'reseller-sign-up',
            component: () => import('@/components/resellers/onboarding/SignUp'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_MINIMAL,
                hideHelpWidget: true,
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/products/playbooks',
            redirect: { path: '/accounts/:dealer_id(\\d+)/products/playbooks' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/products/playbooks',
            name: 'dealer-playbooks',
            component: () => import('./components/playbooks/Playbooks'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ],
                features: [
                    FEATURE_PLAYBOOK,
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/products/playbooks/manage',
            redirect: { path: '/accounts/:dealer_id(\\d+)/products/playbooks/manage' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/products/playbooks/manage',
            name: 'dealer-playbooks-manage',
            component: () => import('./components/playbooks/ManagePlaybooks'),
            props: (route) => ({
                deployedPlay: route.query.deployed_play ? parseInt(route.query.deployed_play) : null,
            }),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ],
                features: [
                    FEATURE_PLAYBOOK,
                ],
            },
        },
        {
            path: '/custom-table-demo',
            name: 'custom-table-demo',
            component: () => import('./components/globals/CustomTable/CustomTableDemo'),
            meta: {
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/products/playbooks/custom',
            redirect: { path: '/accounts/:dealer_id(\\d+)/products/playbooks/custom' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/products/playbooks/custom',
            name: 'dealer-playbooks-custom',
            component: () => import('./components/playbooks/CustomPlaybooks'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ],
                agencies: [
                    125, // LotVantage
                    112, // ADS
                ],
                features: [
                    FEATURE_PLAYBOOK,
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/onboarding-complete',
            redirect: { path: '/accounts/:dealer_id(\\d+)/onboarding-complete' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/onboarding-complete',
            name: 'onboarding-complete',
            component: () => import('./components/onboarding/OnboardingComplete.vue'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_MINIMAL,
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/tools/offline-events/beta',
            redirect: { path: '/accounts/:dealer_id(\\d+)/tools/offline-events/beta' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/tools/offline-events/beta',
            name: 'dealer-offline-events-beta',
            component: () => import('./components/tools/OfflineEventsV2'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                    'client',
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/tools/offline-events',
            redirect: { path: '/accounts/:dealer_id(\\d+)/tools/offline-events' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/tools/offline-events',
            name: 'dealer-offline-events',
            component: () => import('./components/tools/OfflineEvents'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                    'client',
                ],
            },
        },
        {
            path: '/tools/website-inventory-review',
            name: 'website-inventory-review',
            component: () => import('./components/tools/WebsiteInventoryReview'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/tools/pricing-calculator',
            name: 'pricing-calculator',
            component: () => import('./components/tools/PricingCalculator'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                ],
                agencies: [
                    2, // Dealers United
                ],
            },
        },
        {
            path: '/report-builder/preview/:template_id(\\d+)',
            name: 'report-preview',
            component: () => import('./components/report-builder/ReportPreview'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_BLANK,
                hideHelpWidget: true,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/report-builder/templates/:template_id(\\d+)',
            name: 'report-builder',
            component: () => import('./components/report-builder/Builder'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_BLANK,
                hideHelpWidget: true,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/report-builder/templates',
            name: 'report-templates',
            component: () => import('./components/report-builder/ReportTemplates'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/tools/feed-search',
            name: 'feed-search',
            component: () => import('./components/tools/FeedSearch'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    //'agency_dashboard'
                    //'performance_manager',
                ],
            },
        },
        {
            path: '/permission-denied',
            name: 'permission-denied',
            component: () => import('./components/auth/PermissionDenied'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            },
        },
        {
            path: '/gtm-setup/:container_id([A-Za-z0-9\\-]+)',
            name: 'gtm-setup',
            component: () => import('./components/public/GoogleTagManagerSetup'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            },
        },
        {
            path: '/event-flow/:uuid',
            name: 'event-flow-setup',
            component: () => import('./components/public/EventFlowSetup'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            },
        },
        {
            path: '/event-flow-plus/:uuid',
            name: 'event-flow-plus-setup',
            component: () => import('./components/public/EventFlowPlusSetup'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            },
        },
        {
            path: '/ad-previews',
            name: 'ad-previews',
            component: () => import('./components/public/AdPreviews'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_FULL,
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/channel-setup',
            redirect: { path: '/accounts/:dealer_id(\\d+)/channel-setup' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/channel-setup',
            name: 'channel-setup',
            component: () => import('./components/onboarding/channel-setup/Selection.vue'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_MINIMAL,
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/channel-setup/:channel',
            redirect: { path: '/accounts/:dealer_id(\\d+)/channel-setup/:channel' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/channel-setup/:channel',
            name: 'channel-setup-product-selection',
            component: () => import('./components/onboarding/channel-setup/ProductSelection.vue'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_MINIMAL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/dealers/:dealer_id(\\d+)/channel-setup/:channel/complete',
            redirect: { path: '/accounts/:dealer_id(\\d+)/channel-setup/:channel/complete' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/channel-setup/:channel/complete',
            name: 'channel-setup-complete',
            component: () => import('./components/onboarding/channel-setup/Complete.vue'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: false,
                interfaceType: INTERFACE_MINIMAL,
            },
        },
        {
            path: '/library',
            name: 'library',
            component: () => import('./components/library/Library'),
            redirect: () => {
                return { name: 'library-selection' };
            },
            children: [{
                path: '/library-selection',
                name: 'library-selection',
                component: () => import('./components/library/LibrarySelection'),
                meta: {
                    betaDisclaimer: false,
                    requiresAuth: true,
                    interfaceType: INTERFACE_MINIMAL,
                    roles: [
                        'super_admin',
                        'admin',
                    ],
                },
            },
                {
                    path: '/library-main',
                    name: 'library-main',
                    component: () => import('./components/library/LibraryMain'),
                    meta: {
                        betaDisclaimer: false,
                        requiresAuth: true,
                        interfaceType: INTERFACE_MINIMAL,
                        roles: [
                            'super_admin',
                            'admin',
                        ],
                    },
                }],
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_MINIMAL,
                roles: [
                    'super_admin',
                    'admin',
                ],
            },
        },
        {
            path: '/',
            name: 'home',
            redirect: () => {
                return store.state.user.isLoggedIn ? store.getters.userHomepage : { name: 'login' };
            },
        },
        {
            path: '/creative-library',
            name: 'creative-library',
            component: () => import('./components/creative-library/CreativeLibrary'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                ],
            },
        },
        {
            path: '/creative-deployment',
            name: 'creative-deployment',
            component: () => import('./components/creative-library/CreativeDeployment'),
            meta: {
                betaDisclaimer: false,
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                ],
            },
        },
        {
            path: '*',
            name: '404',
            component: () => import('./components/PageNotFound'),
            meta: {
                interfaceType: INTERFACE_FULL,
            },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/campaign-management',
            name: 'campaign-management',
            component: () => import('./components/playbook-management/PlaybookManagement'),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ],
                features: [
                    FEATURE_FACEBOOK_AD_ACCOUNT,
                    FEATURE_SNAPCHAT_AD_ACCOUNT,
                    FEATURE_SNAPCHAT_PUBLIC_PROFILE,
                    FEATURE_TIKTOK_AD_ACCOUNT,
                    FEATURE_TIKTOK_IDENTITY,
                    FEATURE_PINTEREST_AD_ACCOUNT,
                ],
            },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/ad-preview',
            redirect: {
                name: 'ad-preview',
                params: { dealer_id: null },
            },
        },
        {
            path: '/ad-preview',
            name: 'ad-preview',
            component: () => import('./components/playbook-management/ad-preview/AdPreviewPage'),
            meta: {
                requiresAuth: false,
                interfaceType: INTERFACE_MINIMAL,
            },
        },
        {
            path: '/ad-studio',
            name: 'ad-studio',
            component: () => import('./components/ad-studio/index.vue'),
            meta: {
                requiresAuth: false,
                interfaceType: INTERFACE_MINIMAL,
                roles: [
                    'super_admin',
                    'admin',
                ],
            },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/ad-deployment/:ad_deployment_id(\\d+)',
            redirect: { path: '/accounts/:dealer_id(\\d+)/ad-launcher/:ad_deployment_id(\\d+)' },
        },
        {
            path: '/accounts/:dealer_id(\\d+)/ad-launcher/:ad_deployment_id(\\d+)',
            name: 'ad-deployment',
            component: () => import('./components/ad-deployment/AdDeployment.vue'),
            meta: {
                requiresAuth: false,
                interfaceType: INTERFACE_MINIMAL,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard',
                    'client',
                ],
            },
        },
        {
            path: '/ads-library',
            name: 'ads-library',
            component: () => import('./components/ads-library/AdsLibrary'),
            redirect: () => {
                return { name: 'ads-tab' }; // default tab
            },
            children: [{
                path: '/ads-library/ads',
                name: 'ads-tab',
                component: () => import('./components/ads-library/AdsLibraryTab'),
                meta: {
                    betaDisclaimer: false,
                    requiresAuth: true,
                    interfaceType: INTERFACE_FULL,
                    roles: [
                        'super_admin',
                        'admin',
                        'agency_dashboard',
                        'performance_manager',
                    ],
                },
            },
                {
                    path: '/ads-library/playbooks',
                    name: 'playbooks-tab',
                    component: () => import('./components/ads-library/PlaybooksTab'),
                    meta: {
                        betaDisclaimer: false,
                        requiresAuth: true,
                        interfaceType: INTERFACE_FULL,
                        roles: [
                            'super_admin',
                            'admin',
                            'agency_dashboard',
                            'performance_manager',
                        ],
                    },
                },
                {
                    path: '/ads-library/assets',
                    name: 'assets-tab',
                    component: () => import('./components/ads-library/AssetsTab'),
                    meta: {
                        betaDisclaimer: false,
                        requiresAuth: true,
                        interfaceType: INTERFACE_FULL,
                        roles: [
                            'super_admin',
                            'admin',
                            'agency_dashboard',
                            'performance_manager',
                        ],
                    },
                },
                {
                    path: '/ads-library/designs',
                    name: 'designs-tab',
                    component: () => import('./components/ads-library/DesignsTab'),
                    meta: {
                        betaDisclaimer: false,
                        requiresAuth: true,
                        interfaceType: INTERFACE_FULL,
                        roles: [
                            'super_admin',
                            'admin',
                        ],
                    },
                }],
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
        {
            path: '/ads-library/ads/:ad_id(\\d+)',
            name: 'ads-library-preview',
            component: () => import('./components/ads-library/AdsLibraryPreview'),
            props: (route) => ({
                adId: parseInt(route.params.ad_id),
            }),
            meta: {
                requiresAuth: true,
                interfaceType: INTERFACE_FULL,
                roles: [
                    'super_admin',
                    'admin',
                    'agency_dashboard',
                    'performance_manager',
                ],
            },
        },
    ],
});

/**
 * Determine if the given route has been configured with an agency override
 * and if it has determine if the user's agency (if set) matches any of the
 * agencies in the override.  This exists to allow certain agencies to access
 * new routes in advance of a full beta/production release
 */
const routeHasAgencyOverride = (route) => {
    // Ensure there's a route and user agency to proceed
    if (!route || !store.getters.userAgency) {
        return false;
    }

    const agencies = get(route, 'meta.agencies', null) || [];

    if (!store.getters.userIsClient &&
        agencies.includes(store.getters.userAgency.id)) {
        return true;
    }

    return false;
};

//Check for the auth token on every route change
router.beforeEach((to, from, next) => {
    if (to.meta.useRecaptcha) {
        loadRecaptchaScript();
    } else {
        removeRecaptchaScript();
    }

    // Allow the dealer in the URL to set the global dealer
    const routerDealerId = get(to, 'params.dealer_id', null);
    const currentDealerId = get(store.state.dealer, 'currentDealerId', null);
    if (routerDealerId !== null &&
        parseInt(routerDealerId) !== currentDealerId) {
        store.dispatch('updateDealerById', parseInt(routerDealerId));
    }

    // Allow the agency in the URL to set the global agency
    const routerAgencyId = get(to, 'params.agency_id', null);
    const currentAgencyId = get(store.state.agency, 'currentAgency.id', null);

    if (routerAgencyId !== null &&
        parseInt(routerAgencyId) !== currentAgencyId) {
        console.log(1);
        store.dispatch('updateAgencyById', parseInt(routerAgencyId));
    }

    const meta = get(to, 'matched[0].meta', null) || {};

    // If our current route doesn't require auth continue
    if (!meta.requiresAuth) {
        return next();
    }

    // If auth is required but we're not logged in, redirect to login and maintain redirect path
    if (!store.state.user.isLoggedIn) {
        return next({ path: '/login?redirect=' + to.fullPath });
    }


    const hasAccessToSessions = get(store.state.agency, 'currentAgency.configuration.has_white_label_access', null);

    if (meta.requireSessionPermission && !hasAccessToSessions) {
        return next({ name: 'get-session-access' });
    }

    if (meta.verifyIfHasSessionAccess && hasAccessToSessions) {
        return next({ name: 'sessions' });
    }

    // If the route has been configured with an agency override and
    // the agency matches allow it to pass
    if (routeHasAgencyOverride(to.matched[0])) {
        return next();
    }

    // If the user doesn't have permission to view the current page, redirect to permission denied screen
    const requiredRoles = meta.roles || [];
    if (store.state.user.user &&
        !requiredRoles.some(store.getters.userHasRole)) {
        return next({ name: 'permission-denied' });
    }

    // Assume the user has permission and progress
    next();
});

// Helper to more easily use await in async functions
router.pushAsync = (route) => {
    return new Promise((resolve, reject) => {
        router.push(route, resolve, reject);
    });
};

export default router;
