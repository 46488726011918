var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"interface",class:{
        'interface': true,
        'has-external-toolbar': !!_vm.$slots['interface-toolbar-external'],
        'fixed-toolbar': _vm.fixedToolbar
    }},[(!_vm.hideToolbar)?_c('div',{class:{
            'interface-toolbar-wrapper': true,
            'small-toolbar': _vm.smallToolbar,
            'has-toolbar-sections': _vm.toolbarSections.length
        }},[(!!_vm.$slots['interface-toolbar'])?_c('div',{staticClass:"interface-toolbar"},[_vm._t("interface-toolbar")],2):_vm._e(),(!!_vm.$slots['interface-toolbar-external'])?_c('div',{staticClass:"interface-toolbar-external"},[_vm._t("interface-toolbar-external")],2):_vm._e(),_vm._l((_vm.toolbarSections),function(section){return [(!!_vm.$slots[section])?_c('div',{key:section,staticClass:"interface-toolbar-section"},[_vm._t(section)],2):_vm._e()]}),(!!_vm.$slots['interface-actions'])?_c('div',{staticClass:"interface-actions"},[_vm._t("interface-actions")],2):_vm._e()],2):_vm._e(),_c('div',{class:{
            'interface-container': true,
            'interface-container-fixed': _vm.fixedContainerActive
        }},[(!!_vm.$slots['interface-heading'])?_c('div',{staticClass:"interface-heading-wrapper"},[_c('div',{staticClass:"interface-heading"},[_vm._t("interface-heading")],2),(!!_vm.$slots['interface-heading-actions'])?_c('div',{staticClass:"interface-heading-actions"},[_vm._t("interface-heading-actions")],2):_vm._e()]):_vm._e(),_c('div',{class:{
                'interface-body' : true,
                'no-padding': _vm.noPadding
            }},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }