import {
    FEATURE_EVENT_FLOW_PLUS,
    FEATURE_EVENT_FLOW_PLUS_TAG,
    FEATURE_EVENT_FLOW_PLUS_LEGAL,
    FEATURE_EVENT_FLOW_PLUS_DATA_SHARING,
    FEATURE_GOOGLE_IDENTITY,
    FEATURE_GOOGLE_MARKETPLACE,
    FEATURE_GOOGLE_VEHICLE_LISTING_ADS_SETUP,
    FEATURE_GOOGLE_STORE_CODE,
    FEATURE_MICROSOFT_AD_ACCOUNT,
    FEATURE_MICROSOFT_CATALOG,
    FEATURE_MICROSOFT_PIXEL,
    FEATURE_MICROSOFT_VEHICLE_LISTING_ADS_SETUP,
    FEATURE_MICROSOFT_MARKETPLACE_CPC_BILLING_AGREEMENT
} from '@/helpers/globals';

const stepDependenciesApplicable = ({ step, applicableFeatures, existingFeatures }) => {
    return step.dependencies
        // Ignore completed features
        .filter(dependency => !existingFeatures.includes(dependency))
        .some(dependency => {
            return applicableFeatures.includes(dependency);
        });
};

const stepDependenciesRequired = ({ step, requiredFeatures, existingFeatures }) => {
    return step.dependencies
        // Ignore completed features
        .filter(dependency => !existingFeatures.includes(dependency))
        .some(dependency => {
            return requiredFeatures.includes(dependency);
        });
};

const verifyStepDependenciesComplete = ({ step, incompleteFeatures }) => {
    return step.dependencies.every(dependency => !incompleteFeatures.includes(dependency));
};

const verifyRequiredStepDependenciesComplete = ({ step, incompleteFeatures, requiredFeatures }) => {
    return step.dependencies
        .filter(dependency => requiredFeatures.includes(dependency))
        .every(dependency => !incompleteFeatures.includes(dependency));
};

const verifyRequiredStepDependenciesSetup = ({ step, onboardingStatus, requiredFeatures }) => {
    return step.dependencies
        .filter(dependency => requiredFeatures.includes(dependency))
        .every(dependency => {

            // Find the dependency's status and return whether it's missing the initial setup
            const dependencyStatus = onboardingStatus.find(feature => feature.feature === dependency);
            if (!dependencyStatus) {
                return false;
            }
            return !['missing_initial_setup', 'missing_dependencies'].includes(dependencyStatus.status);
        });
};

const verifyStepDependenciesSetup = ({ step, onboardingStatus }) => {
    return step.dependencies
        .every(dependency => {

            // Find the dependency's status and return whether it's missing the initial setup
            const dependencyStatus = onboardingStatus.find(feature => feature.feature === dependency);
            if (!dependencyStatus) {
                return false;
            }
            return !['missing_initial_setup', 'missing_dependencies'].includes(dependencyStatus.status);
        });
};

const ACCOUNT_SETUP_KEY = 'account_setup';
const EVENT_FLOW_PLUS_SETUP_KEY = 'event_flow_setup';
const FACEBOOK_KEY = 'facebook';
const MICROSOFT_KEY = 'microsoft';
const SNAPCHAT_KEY = 'snapchat';
const PINTEREST_KEY = 'pinterest';
const TIKTOK_KEY = 'tiktok';
const GOOGLE_KEY = 'google';
const TARGETING_AND_BUDGET = 'targeting_and_budget';
const REVIEW = 'review';

const toggleShow = (index) => {
    groups[index].show = !groups[index].show;
};

const groups = [
    {
        key: FACEBOOK_KEY,
        name: 'Meta',
        show: false,
        toggleShow,
        icon: '/img/platforms/meta.svg'
    },
    {
        key: ACCOUNT_SETUP_KEY,
        name: 'Account Setup',
        show: false,
        toggleShow,
        icon: null
    },
    {
        key: EVENT_FLOW_PLUS_SETUP_KEY,
        name: 'EventFlowPLUS Setup',
        show: false,
        toggleShow,
        icon: null
    },
    {
        key: MICROSOFT_KEY,
        name: 'Microsoft',
        show: false,
        toggleShow,
        icon: '/img/platforms/microsoft.svg'
    },
    {
        key: SNAPCHAT_KEY,
        name: 'Snapchat',
        show: false,
        toggleShow,
        icon: '/img/platforms/snapchat.svg'
    },
    {
        key: PINTEREST_KEY,
        name: 'Pinterest',
        show: false,
        toggleShow,
        icon: '/img/platforms/pinterest.svg'
    },
    {
        key: TIKTOK_KEY,
        name: 'TikTok',
        show: false,
        toggleShow,
        icon: '/img/platforms/tiktok.svg'
    },
    {
        key: GOOGLE_KEY,
        name: 'Google',
        show: false,
        toggleShow,
        icon: '/img/platforms/google.svg'
    },
    {
        key: TARGETING_AND_BUDGET,
        name: 'Targeting & Budgeting',
        show: false,
        toggleShow,
        icon: null
    },
    {
        key: REVIEW,
        name: 'Review',
        show: false,
        toggleShow,
        icon: null
    }
];

const steps = [
    {
        label: 'Page',
        title: 'Facebook Page',
        key: 'facebook_page',
        dependencies: [
            'facebook_page',
            'facebook_marketplace_page'
        ],
        group_key: FACEBOOK_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/FacebookPage')
    },
    {
        label: 'Assets',
        title: 'Business Manager Assets',
        key: 'facebook_business_manager_assets',
        dependencies: [
            'facebook_ad_account',
            'facebook_offline_event_set',
            'facebook_pixel',
            'facebook_automotive_catalog',
            'facebook_product_catalog'
        ],
        group_key: FACEBOOK_KEY,
        isActive: stepDependenciesRequired,
        isComplete: verifyRequiredStepDependenciesSetup,
        component: () => import('./steps/FacebookBusinessManagerAssets')
    },
    {
        label: 'Ad Account Dependencies',
        title: 'Ad Account Dependencies',
        key: 'ad_account_dependencies',
        dependencies: [
            'facebook_lead_tos',
            'facebook_custom_audience_tos',
            'facebook_ad_account'
        ],
        group_key: FACEBOOK_KEY,
        isActive: stepDependenciesRequired,
        isComplete: verifyRequiredStepDependenciesComplete,
        component: () => import('./steps/AdAccountDependencies'),
        disclaimer: 'Note that BuyerBridge takes no legal responsibility for these agreements. They are strictly agreements between Facebook and the user/organization who accepted them'
    },
    {
        label: 'Call Tracking',
        title: 'Call Tracking',
        key: 'call_tracking',
        dependencies: [
            'twilio'
        ],
        group_key: FACEBOOK_KEY,
        isActive: (params) => {
            return (
                stepDependenciesApplicable(params) &&
                ['US', 'CA'].includes(params.currentDealer.country_code)
            );
        },
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/CallTracking')
    },
    {
        label: 'Chatbot',
        title: 'Chatbot',
        key: 'chatbot',
        dependencies: [
            'chatbot',
        ],
        group_key: FACEBOOK_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/Chatbot')
    },
    {
        label: 'Inventory Type',
        title: 'Inventory Type',
        key: 'inventory_type',
        dependencies: [
            'inventory'
        ],
        group_key: ACCOUNT_SETUP_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: (params) => {
            const dependenciesComplete = verifyStepDependenciesComplete(params);
            // Determine if the dependencies are complete or fall back to
            // whether the inventory type is set.  We do this because the second
            // time around the user won't be able to select an inventory type
            return dependenciesComplete || !!params.data.inventoryType;
        },
        component: () => import('./steps/InventoryType')
    },
    {
        label: 'Website Inventory',
        title: 'Website Inventory',
        key: 'website_inventory',
        dependencies: [
            'website_inventory',
            'inventory'
        ],
        group_key: ACCOUNT_SETUP_KEY,
        multiStepProps: {
            noPadding: true
        },
        isActive: (params) => {
            // If the dependencies are part of a group use the inventoryType
            // to determine if this step is active
            if (
                params.requiredFeatures.includes('inventory') &&
                !params.existingFeatures.includes('inventory')
            ) {
                return (params.data.inventoryType === 'website_inventory');
            }

            // Otherwise for non-grouped situations just fall back to the standard check
            return stepDependenciesRequired(params);
        },
        isComplete: (params) => {

            // Inventory should override the more specific website inventory
            // so find the status of the inventory feature and return whether
            // it's missing the initial setup
            const inventoryStatus = params.onboardingStatus.find(feature => {
                return feature.feature === 'inventory';
            });
            if (inventoryStatus) {
                return ![
                    'missing_initial_setup',
                    'missing_dependencies'
                ].includes(inventoryStatus.status);
            }

            // Find the status of the website_inventory feature and return
            // whether it's missing the initial setup
            const websiteInventoryStatus = params.onboardingStatus.find(feature => {
                return feature.feature === 'website_inventory';
            });
            if (websiteInventoryStatus) {
                return ![
                    'missing_initial_setup',
                    'missing_dependencies'
                ].includes(websiteInventoryStatus.status);
            }

            // Guarding in between state changes
            return false;
        },
        component: () => import('./steps/WebsiteInventory')
    },
    {
        label: 'Feed Inventory',
        title: 'Feed Inventory',
        key: 'feed_inventory',
        dependencies: [
            'inventory'
        ],
        group_key: ACCOUNT_SETUP_KEY,
        isActive: (params) => (params.data.inventoryType === 'feed_inventory'),
        isComplete: verifyStepDependenciesSetup,
        component: () => import('./steps/FeedInventory')
    },
    {
        label: 'Inventory Status',
        title: 'Inventory Status',
        key: 'inventory_status',
        dependencies: [
            'inventory',
            'website_inventory'
        ],
        group_key: ACCOUNT_SETUP_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: (params) => {

            // Inventory should override the more specific website inventory
            // so if the onboarding includes inventory determine if it's complete
            if (params.applicableFeatures.includes('inventory')) {
                return !params.incompleteFeatures.includes('inventory');
            }

            // Fall back to determine if website inventory is complete
            if (params.applicableFeatures.includes('website_inventory')) {
                return !params.incompleteFeatures.includes('website_inventory');
            }

            // Guarding in between state changes
            return false;
        },
        component: () => import('./steps/InventoryStatus')
    },
    /*{
        label: 'Location',
        title: 'Account Location',
        key: 'account_location',
        dependencies: [
            'facebook_audience_location'
        ],
        group_key: ACCOUNT_SETUP_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/Location')
    },*/
    {
        label: 'Lead Destinations',
        title: 'Lead Destinations',
        key: 'lead_destinations',
        dependencies: [
            'lead_destination'
        ],
        group_key: ACCOUNT_SETUP_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/Leads')
    },
    {
        label: 'Data Sharing Agreement',
        title: 'Data Sharing Agreement',
        key: 'event_flow_plus_data_sharing',
        dependencies: [
            FEATURE_EVENT_FLOW_PLUS_DATA_SHARING
        ],
        group_key: EVENT_FLOW_PLUS_SETUP_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/EventFlowPlusDataSharing')
    },
    {
        label: 'Privacy Banner Selection',
        title: 'Privacy Banner Selection',
        key: 'event_flow_plus_legal',
        dependencies: [
            FEATURE_EVENT_FLOW_PLUS_LEGAL
        ],
        group_key: EVENT_FLOW_PLUS_SETUP_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/EventFlowPlusBanner')
    },
    {
        label: 'DNS Setup',
        title: 'DNS Setup',
        key: 'event_flow_plus',
        dependencies: [
            FEATURE_EVENT_FLOW_PLUS
        ],
        group_key: EVENT_FLOW_PLUS_SETUP_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/EventFlowPlus')
    },
    {
        label: 'Tag Installation',
        title: 'Tag Installation',
        key: 'event_flow_plus_tag',
        dependencies: [
            FEATURE_EVENT_FLOW_PLUS_TAG
        ],
        group_key: EVENT_FLOW_PLUS_SETUP_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: (params) => {
            return !!params.data.tagInstallationCompleted;
        },
        component: () => import('./steps/EventFlowPlusTag')
    },
    {
        label: 'EventFlow',
        title: 'EventFlow Set Up',
        key: 'event_flow',
        dependencies: [
            'event_flow'
        ],
        group_key: ACCOUNT_SETUP_KEY,
        isActive: (params) => {
            // regular EventFlow should not be active if we have EventFlowPLUS!
            if (params.applicableFeatures.includes(FEATURE_EVENT_FLOW_PLUS)) {
                return false;
            }
            return stepDependenciesApplicable(params);
        },
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/EventFlow')
    },
    {
        label: 'Asset Connection',
        title: 'Asset Connection',
        key: 'asset_connection',
        dependencies: [
            'snapchat_ad_account',
            'snapchat_pixel',
            'snapchat_public_profile',
            'snapchat_catalog'
        ],
        group_key: SNAPCHAT_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/SnapchatAssetConnection')
    },
    {
        label: 'Asset Connection',
        title: 'Asset Connection',
        key: 'asset_connection',
        dependencies: [
            'tiktok_ad_account',
            'tiktok_pixel',
            'tiktok_catalog'
        ],
        group_key: TIKTOK_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/TiktokAssetConnection')
    },
    {
        label: 'Asset Connection',
        title: 'Asset Connection',
        key: 'asset_connection',
        dependencies: [
            FEATURE_MICROSOFT_AD_ACCOUNT,
            FEATURE_MICROSOFT_CATALOG,
            FEATURE_MICROSOFT_PIXEL,
        ],
        group_key: MICROSOFT_KEY,
        isActive: ({ requiredFeatures }) => requiredFeatures.includes(FEATURE_MICROSOFT_VEHICLE_LISTING_ADS_SETUP),
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/MicrosoftAssetConnection')
    },
    {
        label: 'Inventory Ads Setup',
        title: 'Inventory Ads Setup',
        key: 'microsoft_inventory_ads_setup',
        dependencies: [
            FEATURE_MICROSOFT_VEHICLE_LISTING_ADS_SETUP
        ],
        group_key: MICROSOFT_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/MicrosoftAdsSetup')
    },
    {
        label: 'Biling Agreement',
        title: 'Biling Agreement',
        key: 'microsoft_marketplace_cpc_billing_agreement',
        dependencies: [
            FEATURE_MICROSOFT_MARKETPLACE_CPC_BILLING_AGREEMENT
        ],
        group_key: MICROSOFT_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/MicrosoftMarketplaceCpcBilingAgreement')
    },
    {
        label: 'Tikok Identity',
        title: 'Tiktok Identity',
        key: 'tiktok_identity',
        dependencies: [
            'tiktok_identity'
        ],
        group_key: TIKTOK_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/TiktokIdentity')
    },
    {
        label: 'Business Profile',
        title: 'Business Connection',
        key: 'google_business_profile',
        dependencies: [
            FEATURE_GOOGLE_IDENTITY
        ],
        group_key: GOOGLE_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/GoogleBusinessProfile')
    },
    {
        label: 'Asset Connection',
        title: 'Asset Connection',
        key: 'asset_connection',
        dependencies: [
            FEATURE_GOOGLE_STORE_CODE,
        ],
        group_key: GOOGLE_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/GoogleAssetConnection')
    },
    {
        label: 'Vehicle Listing Ads Setup',
        title: 'Vehicle Listing Ads Setup',
        key: 'google_vehicle_listing_ads_setup',
        dependencies: [
            FEATURE_GOOGLE_VEHICLE_LISTING_ADS_SETUP,
        ],
        group_key: GOOGLE_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/GoogleListingAdsSetup')
    },
    {
        label: 'Google Checklist',
        title: 'Google Checklist',
        key: 'google_marketplace_checklist',
        dependencies: [
            FEATURE_GOOGLE_MARKETPLACE
        ],
        group_key: GOOGLE_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: ({ data, dealerInventoryType }) => (
            data.googleMarketplaceListingsVerified === true &&
            data.googleMarketplaceProviderVerified === true &&
            (
                dealerInventoryType === 'feed_inventory'
                    ? data.googleMarketplaceOverlaysVerified === true
                    : true
            )
        ),
        component: () => import('./steps/GoogleMarketplaceChecklist')
    },
    {
        label: 'Asset Connection',
        title: 'Asset Connection',
        key: 'asset_connection',
        dependencies: [
            'pinterest_ad_account',
            'pinterest_pixel',
            'pinterest_catalog'
        ],
        group_key: PINTEREST_KEY,
        isActive: stepDependenciesApplicable,
        isComplete: verifyStepDependenciesComplete,
        component: () => import('./steps/PinterestAssetConnection')
    },
    {
        label: 'Targeting',
        title: 'Targeting',
        key: 'play_targeting',
        dependencies: [],
        group_key: TARGETING_AND_BUDGET,
        isActive: ({ type }) => type === 'play',
        isComplete: ({ onboardingUserAdSetsValid }) => onboardingUserAdSetsValid,
        component: () => import('./steps/PlayTargeting')
    },
    {
        label: 'Budgeting',
        title: 'Budgeting',
        key: 'play_budgeting',
        dependencies: [],
        group_key: TARGETING_AND_BUDGET,
        isActive: ({ type }) => type === 'play',
        isComplete: ({ onboardingUserCampaignsValid }) => onboardingUserCampaignsValid,
        component: () => import('./steps/PlayBudgeting'),
        disclaimer: 'Note that maintaining advertising expenditures on Facebook is solely the user\'s responsibility and BuyerBridge maintains no liability related to spend (e.g. over/under spending monthly targets, etc).'
    },
    {
        label: 'Landing Page Urls',
        title: 'Landing Page Urls',
        key: 'play_landing_page_urls',
        dependencies: [],
        group_key: TARGETING_AND_BUDGET,
        isActive: ({ type, playAdCreatives }) => type === 'play' && playAdCreatives?.length,
        isComplete: ({ onboardingUserAdCreativesValid }) => onboardingUserAdCreativesValid,
        component: () => import('./steps/PlayLandingPageUrls')
    },
    {
        label: 'Review',
        title: 'Review Play',
        key: 'play_review',
        group_key: REVIEW,
        dependencies: [],
        isActive: ({ type }) => type === 'play',
        component: () => import('./steps/PlayReview')
    },
    {
        label: 'Review',
        title: 'Review Product',
        key: 'product_review',
        group_key: REVIEW,
        dependencies: [],
        isActive: ({ type }) => type === 'product',
        component: () => import('./steps/ProductReview')
    }
];

export default steps;
export { groups };
