import moment from 'moment';

/**
 * Retrieves the default date range comparison date range
 * 
 * @todo Let the user control increments and move/remove this
 * 
 */
export const getDefaultDateRangeComparison = (dateRange) => {
    
    const daysBetween = moment(dateRange.endDate)
            .diff(dateRange.startDate, 'days');
    
    const startDate = moment(dateRange.startDate)
            .subtract(daysBetween, 'days')
            .startOf('day')
            .toDate();

    const endDate = moment(dateRange.startDate)
            .subtract(1, 'day')
            .endOf('day')
            .toDate();

    return { startDate, endDate };
};
