import HttpClient from '@/http';

const getDealerVehiclesById = ({ dealerId, vehicleIds }) => {
    return (new HttpClient()).post(`/dealers/${dealerId}/all-vehicles/search`, {
        query: {
            bool: {
                should: [
                    { terms: { vehicle_export_id: vehicleIds } },
                ],
            }
        },
        _source: [
            'vehicle_id',
            'images',
            'vin',
            'stock_number',
            'used',
            'certified',
            'year',
            'price',
            'trim_name',
            'make_name',
            'model_name',
            'body_name',
            'created_at',
            'deleted_at',
            'vehicle_export_id'
        ],
        script_fields: {
            image: {
                script: {
                    /* eslint-disable-next-line */
                    source: "(params['_source']['images'].length > 0) ? params['_source']['images'][0] : ''"
                }
            }
        },
        size: 5000
    });
};

export default getDealerVehiclesById;
