import ApiRepository from '@/api-repository';

import {
    SET_DEALER_ALERTS,
    SET_DEALER_ALERTS_LOADING,
    SET_AGENCY_ALERTS,
    SET_AGENCY_ALERTS_LOADING,
    START_REFRESH_ALERT_TYPE,
    COMPLETE_REFRESH_ALERT_TYPE,
} from '../mutation-types';

const apiRepository = new ApiRepository();

export default {
    state: {
        dealerAlertsLoading: false,
        dealerAlerts: [],
        agencyAlertsLoading: false,
        agencyAlerts: [],
        refreshingAlerts: [],
    },
    getters: {},
    actions: {
        async getDealerAlerts({ commit }, dealerId) {
            try {
                commit(SET_DEALER_ALERTS_LOADING, true);

                const response = await apiRepository.getDealerAlerts(dealerId);

                const alerts = response.data?.data || [];

                commit(SET_DEALER_ALERTS, alerts);
            } catch (error) {
                console.error('Error retrieving dealer alerts', error);
            } finally {
                commit(SET_DEALER_ALERTS_LOADING, false);
            }
        },
        async getAgencyAlerts({ commit }, payload) {
            try {
                commit(SET_AGENCY_ALERTS_LOADING, true);

                const response = await apiRepository.getAgencyAlerts(payload);

                const alerts = response.data?.data || [];

                commit(SET_AGENCY_ALERTS, alerts);

                return response;
            } catch (error) {
                console.error('Error retrieving agency alerts', error);
            } finally {
                commit(SET_AGENCY_ALERTS_LOADING, false);
            }
        },
        async refreshAlert({ commit }, { dealerId, alertTypeId }) {
            try {
                commit(START_REFRESH_ALERT_TYPE, { dealerId, alertTypeId });

                const response =  await apiRepository.refreshAlertType({ dealerId, alertTypeId });

                commit(COMPLETE_REFRESH_ALERT_TYPE, { dealerId, alertTypeId });

                return response;
            } catch(error) {
                console.error('Error refreshing alert type', { dealerId, alertTypeId, error });
            }

        }
    },
    mutations: {
        [SET_DEALER_ALERTS](state, alerts) {
            state.dealerAlerts = alerts;
        },
        [SET_DEALER_ALERTS_LOADING](state, isLoading) {
            state.dealerAlertsLoading = isLoading;
        },
        [SET_AGENCY_ALERTS](state, alerts) {
            state.agencyAlerts = alerts;
        },
        [SET_AGENCY_ALERTS_LOADING](state, isLoading) {
            state.agencyAlertsLoading = isLoading;
        },
        [START_REFRESH_ALERT_TYPE](state, alert) {
            state.refreshingAlerts.push(alert);
        },
        [COMPLETE_REFRESH_ALERT_TYPE](state, alert) {
            const index = state.refreshingAlerts.findIndex(refreshing => refreshing.dealerId == alert.dealerId && refreshing.alertTypeId == alert.alertTypeId);
            state.refreshingAlerts.splice(index, 1);
        },
    }
};