<template>
    <div
        ref="interface"
        :class="{
            'interface': true,
            'has-external-toolbar': !!$slots['interface-toolbar-external'],
            'fixed-toolbar': fixedToolbar
        }">
        <div
            v-if="!hideToolbar"
            :class="{
                'interface-toolbar-wrapper': true,
                'small-toolbar': smallToolbar,
                'has-toolbar-sections': toolbarSections.length
            }">
            <div
                v-if="!!$slots['interface-toolbar']"
                class="interface-toolbar">
                <slot name="interface-toolbar" />
            </div>
            <div
                v-if="!!$slots['interface-toolbar-external']"
                class="interface-toolbar-external">
                <slot name="interface-toolbar-external" />
            </div>
            <template v-for="section in toolbarSections">
                <div
                    v-if="!!$slots[section]"
                    :key="section"
                    class="interface-toolbar-section">
                    <slot :name="section" />
                </div>
            </template>
            <div
                v-if="!!$slots['interface-actions']"
                class="interface-actions">
                <slot name="interface-actions" />
            </div>
        </div>
        <div
            :class="{
                'interface-container': true,
                'interface-container-fixed': fixedContainerActive
            }">
            <div
                v-if="!!$slots['interface-heading']"
                class="interface-heading-wrapper">
                <div class="interface-heading">
                    <slot name="interface-heading" />
                </div>
                <div
                    v-if="!!$slots['interface-heading-actions']"
                    class="interface-heading-actions">
                    <slot name="interface-heading-actions" />
                </div>
            </div>
            <div
                :class="{
                    'interface-body' : true,
                    'no-padding': noPadding
                }">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '@/event-bus';
import { get, debounce } from 'lodash';
import SyncBodyClassMixin from '@/mixins/sync-body-class';

export default {
    mixins: [
        SyncBodyClassMixin('has-styled-interface')
    ],
    props: {
        hideToolbar: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        noOverflow: {
            type: Boolean,
            default: false
        },
        fixedContainer: {
            type: Boolean,
            default: false
        },
        smallToolbar: {
            type: Boolean,
            default: false
        },
        fixedToolbar: {
            type: Boolean,
            default: false
        },
        toolbarSections: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            fixedContainerActive: false
        };
    },
    created() {
        if (this.fixedContainer) {

            this.onResize = debounce(() => {
                this.handleWindowSize();
            }, 300);

            window.addEventListener('resize', this.onResize.bind(this));

            EventBus.$on('menu-toggle', () => {
                this.handleWindowSize();
            });
        }
    },
    mounted() {
        this.toggleActiveClass(true);
        if (this.fixedContainer) {
            this.handleWindowSize();
        }
    },
    activated() {
        this.toggleActiveClass(true);
    },
    deactivated() {
        this.toggleActiveClass(false);
    },
    destroyed() {
        this.toggleActiveClass(false);

        if (this.fixedContainer) {
            window.removeEventListener('resize', this.onResize.bind(this));
        }
    },
    methods: {
        handleWindowSize() {
            const clientWidth = get(this.$refs, 'interface.clientWidth', null) || 0;
            this.fixedContainerActive = (clientWidth > 1264);
        },
        toggleActiveClass(active) {
            if (active && this.noOverflow) {
                document.body.classList.add('has-no-overflow');
            } else {
                document.body.classList.remove('has-no-overflow');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.interface {
    height: 100%;
    display: flex;
    flex-direction: column; // Allow UI to fill height
    &.fixed-toolbar {
        .interface-container {
            @media (min-width: $bp-lg) {
                margin-top: 70px;
            }
        }
        .interface-toolbar-wrapper {
            @media (min-width: $bp-lg) {
                position: fixed;
                width: 100%;
                z-index: 100;
            }
        }

    }
    &:not(.has-external-toolbar) {
        .interface-toolbar-wrapper {
            align-items: center;
            background-color: $alabaster-dark;
            padding: 2rem;
            @media (min-width: $bp-md) {
                display: flex;
            }
            &.small-toolbar {
                border-bottom: 1px solid $gray-light;
                padding: 0;
            }
            &.has-toolbar-sections {
                padding: 2rem;
                @media (min-width: $bp-md) {
                    padding: 0 2rem;
                }
                .interface-toolbar,
                .interface-toolbar-section {
                    @media (min-width: $bp-md) {
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                    @media (min-width: $bp-lg) {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        height: 70px;
                    }
                }
            }
            @media print {
                padding: 0;
            }
        }
    }
    .interface-toolbar {
        display: flex;
        align-items: center;
        flex: 1;
        flex-wrap: wrap;
        @media (min-width: $bp-md) {
            & > * {
                max-width: 300px;
            }
        }
    }
    .interface-toolbar-section {
        margin: 0 0 20px 0;
        @media (min-width: $bp-md) {
            min-height: 100%;
            border-right: 1px solid $gray-light;
            padding-right: 14px;
            margin: 0 14px 0 0;
            flex: 1;
        }
        @media (min-width: $bp-lg) {
            min-height: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex: none;
            flex-direction: row;
        }
        & > * {
            margin: 0 0 20px 0;
            @media (min-width: $bp-lg) {
                margin: 0 8px 0;
            }
            &:last-child {
                margin: 0;
            }
        }
        &:last-child {
            border-right: none;
            margin: 0;
        }
    }
    .interface-container {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .interface-container-fixed {
        width: $bp-lg;
        margin: 0 auto;
    }
    .interface-heading-wrapper {
        display: flex;
        align-items: center;
        padding: 1.2rem 2rem 0;
    }
    .interface-heading {
        color: $gray;
        font-size: 1.2rem;
    }
    .interface-heading-actions {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
    .interface-body {
        padding: 0;
        @media (min-width: $bp-sm) {
            padding: 32px;
        }
        flex: 1;
        @media print {
            padding: 0;
        }
        &.no-padding {
            padding: 0;
        }
    }
}
</style>

<style lang="scss">

/* Allow UIs with large absolute elements to overflow if needed */
.has-no-overflow {
    background: $alabaster;
    overflow: visible;
}


.interface-toolbar-wrapper {
    &.small-toolbar {
        .v-select__slot {
            @media (min-width: $bp-lg) {
                max-width: 180px;
            }
        }
    }
}

/* Legacy overrides */
.has-styled-interface {
    .primary-container {
        & > .container.fluid {
            padding: 0;
        }
    }
    .styled-select.v-text-field {
        padding-top: 15px;
        margin-top: 0px;
        .v-input__slot {
            margin-bottom: 0px;
        }
    }
}
</style>
