<template>
    <div>
        <!-- START openOnClick WITH external control -->
        <template v-if="openOnClick">
            <div
                ref="activator"
                class="styled-tooltip-activator">
                <slot />
            </div>
            <v-tooltip
                ref="tooltip"
                :value="value"
                :lazy="lazy"
                :content-class="computedContentClass"
                :max-width="currentMaxWidth"
                :activator="$refs.activator"
                :disabled="disabled"
                :debounce="debounce"
                :open-delay="openDelay"
                :close-delay="closeDelay"
                :nudge-top="nudgeTop"
                :nudge-right="nudgeRight"
                v-bind="tooltipPositionProps"
                @input="$emit('input', $event)">
                <div
                    ref="tooltipContent"
                    class="styled-tooltip-content"
                    :class="{'no-padding': noPadding, 'rounded-border': roundedBorder}">
                    <slot name="content" />
                    <span class="styled-tooltip-arrow" />
                </div>
            </v-tooltip>
        </template>
        <!-- END openOnClick WITH external control -->

        <!-- START standard hover behavior -->
        <template v-else>
            <v-tooltip
                ref="tooltip"
                :value="value"
                :lazy="lazy"
                :content-class="computedContentClass"
                :max-width="currentMaxWidth"
                :disabled="disabled"
                :debounce="debounce"
                :open-delay="openDelay"
                :close-delay="closeDelay"
                :nudge-top="nudgeTop"
                :nudge-right="nudgeRight"
                v-bind="tooltipPositionProps"
                @input="$emit('input', $event)">
                <template #activator="{ on }">
                    <span v-on="on">
                        <slot />
                    </span>
                </template>
                <div
                    v-if="hasContent"
                    ref="tooltipContent"
                    class="styled-tooltip-content"
                    :class="{'no-padding': noPadding, 'rounded-border': roundedBorder}">
                    <slot name="content" />
                    <span class="styled-tooltip-arrow" />
                </div>
            </v-tooltip>
        </template>
        <!-- END standard hover behavior -->
    </div>
</template>

<script>
import debounce from 'debounce';

export default {
    props: {
        maxWidth: {
            type: Number,
            default: 400
        },
        contentClass: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        },
        highIndex: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        position: {
            default: 'left',
            validator(value) {
                return ['top', 'right', 'right-far' ,'bottom', 'left'].includes(value);
            }
        },
        offsetPosition: {
            default: undefined,
            validator(value) {
                return ['right', 'left'].includes(value);
            }
        },
        openOnClick: {
            type: Boolean,
            default: false
        },
        customOffset: {
            type: [String, Number],
            default: undefined
        },
        lazy: {
            type: Boolean,
            default: true
        },
        debounce: {
            type: [String, Number],
            default: 0
        },
        openDelay: {
            type: [String, Number],
            default: 200
        },
        closeDelay: {
            type: [String, Number],
            default: 200
        },
        nudgeTop: {
            type: [String, Number],
            default: 0
        },
        nudgeRight: {
            type: [String, Number],
            default: 0
        },
        wordBreak: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        roundedBorder: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activePosition: '',
            currentMaxWidth: 0,
            hasContent: false,
            tooltipOpen: false
        };
    },
    computed: {
        tooltipPositionProps() {
            switch (this.activePosition) {
                case 'top':
                    return {
                        top: true,
                        right: false,
                        bottom: false,
                        left: false
                    };
                case 'right':
                    return {
                        top: false,
                        right: true,
                        bottom: false,
                        left: false
                    };
                case 'right-far':
                    return {
                        top: true,
                        right: false,
                        bottom: false,
                        left: false
                    };
                case 'bottom':
                    return {
                        top: false,
                        right: false,
                        bottom: true,
                        left: false
                    };
                default:
                    return {
                        top: false,
                        right: false,
                        bottom: false,
                        left: true
                    };
            }
        },
        computedContentClass() {
            //  Set the default class
            const classes = ['styled-tooltip'];
            // Append the user defined class
            if (this.contentClass) {
                classes.push(this.contentClass);
            }

            // Allow user to use the default higher index
            if (this.highIndex) {
                classes.push('styled-tooltip--high-index');
            }

            if (this.offsetPosition) {
                classes.push(`styled-tooltip--offset-${this.offsetPosition}`);
            }

            if (this.wordBreak) {
                classes.push('word-break');
            }

            // Use the left or right class based on whether the positionLeft is true
            classes.push(
                this.getTooltipClass()
            );

            return classes.join(' ');
        }
    },
    watch: {
        value() {
            if (this.value && this.customOffset) {
                this.$nextTick(function() {
                    const tooltipContent = this.$refs.tooltipContent.parentElement;
                    const arrow = this.$refs.tooltipContent.querySelector('.styled-tooltip-arrow');
                    this.customOffset = +this.customOffset - 60;
                    tooltipContent.style.top = this.customOffset + 'px';
                    arrow.style.top = '75px';
                    //fallback
                    setTimeout(() => {tooltipContent.style.top = this.customOffset + 'px'}, 0);
                });
            }
        }
    },
    mounted() {
        // Set the active position from props
        this.activePosition = this.position;

        // Take in the default prop
        this.currentMaxWidth = this.maxWidth;

        // Just in case, calculate the position
        this.handleToolTipPosition();

        // Support window resizes (this also covers toggling the nav)
        // Debounce to avoid heavy processing
        this.onWindowResize = debounce(() => {
            this.handleWindowResize();
        }, 300);
        window.addEventListener('resize', this.onWindowResize.bind(this));

        if (this.openOnClick) {
            document.addEventListener('click', this.handleDocumentClick.bind(this));
        }
        this.hasContent = this.checkForSlotContent();
    },
    updated() {
        // 'computed' is not tracking the slots! use the method instead
        this.hasContent = this.checkForSlotContent();
    },
    destroyed() {
        // Properly drop the listener to avoid memory leaks
        window.removeEventListener('resize', this.onWindowResize.bind(this));

        if (this.openOnClick) {
            document.removeEventListener('click', this.handleDocumentClick.bind(this));
        }
    },
    methods: {
        checkForSlotContent() {
            const hasContent = this.$slots.content?.length ?? null;
            return hasContent;
        },
        handleWindowResize() {
            this.handleToolTipPosition();
            this.$emit('input', false);
        },
        handleDocumentClick(event) {
            const { tooltipContent } = this.$refs;

            // If there's no tooltip ref or it's closed
            // or we're clicking the activator there's nothing to do
            if (!tooltipContent
                || !this.value
                || event.target === this.$refs.activator
                || this.$refs.activator.contains(event.target)) {
                return;
            }

            if (!tooltipContent.contains(event.target)) {

                // Close the tooltip when the user clicks outside
                this.$emit('input', false);
            }
        },
        getTooltipClass() {
            return `styled-tooltip--${this.activePosition}`;
        },
        handleToolTipPosition() {
            // If the status isn't active and there's no ref there's nothing to do
            if (!this.$refs.tooltip) {
                return;
            }

            // Always make the position the in small breakpoints
            // unless we're already on the bottom
            if (window.innerWidth < 768 && this.activePosition !== 'bottom') {
                this.activePosition = 'top';
                this.currentMaxWidth = 300;
                return;
            }

            // Reset to default
            this.currentMaxWidth = this.maxWidth;

            // Retrieve stats on the activator's position and determine
            // if we're too close to the edge and switch sides if so
            /* const activatorRect = this.$refs.tooltip.$el.getBoundingClientRect();

            // Operate on the left position
            if (activatorRect.left < 50 && this.activePosition === 'left') {
                this.activePosition = 'right';
            // Move it back to the left
            }  else if (activatorRect.left >= 350 && this.activePosition === 'right') {
                this.activePosition = 'left';
            } */
        }
    }
};
</script>

<style lang="scss">

.styled-tooltip-activator {
    display: inline-block;
}

.styled-tooltip {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    &.menuable__content__active {
        opacity: 1 !important;
    }

    /* Be specific here to override vuetify */
    &.v-tooltip__content {
        background: white;
        color: $gray-dark;
        box-shadow: none;
        padding: 0;

        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.3);

        .styled-tooltip-content {
            position: relative;
            padding: 20px;
            width: 100%;

            li {
                margin-bottom: 0;
            }
        }
    }

    &.v-tooltip__content:not(.styled-tooltip--high-index) {
        z-index: 3050 !important;
        //width: 290px;
    }

    .styled-tooltip-arrow {
        width: 30px;
        height: 15px;
        margin-top: -7.5px;
        position: absolute;
        top: 50%;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            background: white;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
            top: 0;
            left: 50%;
            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
        }
    }
    &--left {
        .styled-tooltip-arrow {
            left: 100%;
            transform: translateX(-25%) rotate(-90deg);
        }
    }
    &--right {
        .styled-tooltip-arrow {
            right: 100%;
            transform: translateX(25%) rotate(-270deg);
        }
    }
    &--right-far {
        .styled-tooltip-content{
            padding:10px !important;
        }
        .styled-tooltip-arrow {
            left: 15px;
            margin-left: -15px;
            transform: translateY(300%);
            bottom: 30px;
            top: auto;
            margin-top: 0;
        }
    }
    &--top {
        .styled-tooltip-arrow {
            left: 50%;
            margin-left: -15px;
            transform: translateY(300%);
            bottom: 30px;
            top: auto;
            margin-top: 0;
        }
    }
    &--bottom {
        .styled-tooltip-arrow {
            left: 50%;
            margin-left: -15px;
            transform: translateY(-300%) rotate(180deg);
            top: 30px;
            bottom: auto;
            margin-top: 0;
        }
    }
    &--offset-left {
        transition: transform 0.2s ease-in-out;
        &.menuable__content__active {
            transform: translate(calc(-50% + 20px), 0%);
        }
        @media (min-width: $bp-md) {
            transform: translate(calc(-50% + 20px), 10%);
            .styled-tooltip-arrow {
                left: calc(100% - 20px);
            }
        }
    }
    &--offset-right {
        transition: transform 0.2s ease-in-out;
        &.menuable__content__active {
            transform: translate(calc(50% - 20px), 0%);
        }
        @media (min-width: $bp-md) {
            transform: translate(calc(50% - 20px), 10%);
            .styled-tooltip-arrow {
                left: calc(0% + 20px);
            }
        }
    }
    &.word-break {
        word-break: break-all;
    }
}
.styled-tooltip.v-tooltip__content .styled-tooltip-content.no-padding {
    padding: 0;
}
.styled-tooltip.v-tooltip__content:has(> .styled-tooltip-content.rounded-border) {
    border-radius: 8px;
}
</style>
