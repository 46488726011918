export const USER_LOCATION_STORAGE_KEY = 'userLocation_v2';
export const USER_STORAGE_KEY = 'user_v2';
export const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';

export const AGENCY_STORAGE_KEY = 'currentAgency_v2';

export const BRAND_STORAGE_KEY = 'currentAgencyBrand_v2';

export const DEALER_STORAGE_KEY = 'currentDealer_v2';

export const SETTINGS_STORAGE_KEY = 'currentSettings_v1';

export const METRICS_STORAGE_KEY = 'currentMetrics_v1';

export const METRICS_FACEBOOK_ADS_STORAGE_KEY = 'currentMetricsFacebookAds_v1';

export const METRICS_GA_STORAGE_KEY = 'currentMetricsGoogle_v1';

export const TRUSTED_DEVICE_TOKEN = 'trustedDeviceToken';
