import getInitialData from './getInitialData';
import aggregate from './modules/aggregate';
import facebook from './modules/facebook';
import snapchat from './modules/snapchat';
import tiktok from './modules/tiktok';
import pinterest from './modules/pinterest';
// import google from './modules/google';
import getters from './getters';
import mutations from './mutations';

const initialData = getInitialData();

export default {
    namespaced: true,
    modules: {
        aggregate,
        facebook,
        snapchat,
        tiktok,
        pinterest,
        // google,
    },
    state: {
        dateRange: initialData.dateRange,
        dateRangeComparison: initialData.dateRangeComparison,
        dateRangeIncrement: initialData.dateRangeIncrement,
        spendOverrideDisable: initialData.spendOverrideDisable,
        interval: initialData.interval
    },
    getters,
    mutations
};
