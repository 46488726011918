<template>
    <div class="remote-content-link">
        <a
            v-if="config.type == 'link'"
            v-bind="config.props">
            <slot />
        </a>
        <a
            v-if="config.type == 'click'"
            href="#"
            @click.prevent="config.onClick">
            <slot />
        </a>
        <router-link
            v-if="config.type == 'route'"
            v-bind="config.props">
            <slot />
        </router-link>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'RemoteContentLink',
    props: {
        href: {
            type: String,
            required: true
        },
        target: {
            type: String,
            default: '_self'
        },
        dealer: {
            type: Object,
            required: true
        },
        ticketData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            url: null
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency
        }),
        config() {
            if (this.url == null) {
                return {
                    type: 'link',
                    props: {
                        href: this.href,
                        target: this.target
                    }
                };
            }


            if (this.url.hash == '#facebook-billing') {
                return {
                    type: 'link',
                    props: {
                        href: `https://business.facebook.com/ads/manager/account_settings/account_billing/?act=${this.dealerFacebookAdAccount}&business_id=${this.currentAgency.facebook_business_id}&page=account_settings&tab=account_billing_settings`,
                        target: '_blank'
                    }
                };
            }

            if (this.url.hash == '#facebook-disapproved-ads') {
                return {
                    type: 'link',
                    props: {
                        href: `https://business.facebook.com/adsmanager/manage/ads?act=${this.dealerFacebookAdAccount}&business_id=${this.currentAgency.facebook_business_id}&filter_set=ADGROUP_DELIVERY_STATUS-STRING_SET%1EIN%1E[%22error%22]`,
                        target: '_blank'
                    }
                };
            }

            if (this.url.hash == '#open-ticket') {
                return {
                    type: 'click',
                    onClick: () => {
                        this.createTicket({
                            ...this.ticketData,
                            dealers: [this.dealer]
                        });
                    }
                };
            }

            if (this.isValidRoute(this.url.pathname)) {
                return {
                    type: 'route',
                    props: {
                        to: this.url.pathname + this.url.hash
                    }
                };
            }

            return {
                type: 'link',
                props: {
                    href: this.href,
                    target: this.target
                }
            };
        },
        dealerFacebookAdAccount() {
            const accountId = this.dealer?.facebook_ad_account_annotations?.data?.[0]?.facebook_ad_account_id || '';
            return accountId.replace('act_', '');
        }
    },
    watch: {
        href: {
            handler(value) {
                try {
                    this.url = new URL(value);
                    this.url.searchParams = new URLSearchParams(this.url.search);
                } catch (e) {
                    this.url = null;
                }
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions(['createTicket']),
        isValidRoute(pathname) {
            const resolved = this.$router.resolve(pathname);
            return resolved.route.name !== '404';
        }
    }
};
</script>
