import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import steps from '../steps';

export default {
    strict: true,
    state: {
        initializing: false,
        steps,
        statusLoading: false,
        existingFeatures: [],
        data: {},
        onboardingId: null,
        type: null,
        error: null,
        productIds: [],
        products: [],
        productLoading: false,
        playId: null,
        play: null,
        playLoading: false,
        playCampaigns: [],
        playAdSets: [],
        playAdCreatives: [],
        skipDisclaimerOpen: false,
        currentStep: 0,
        latestStep: 0,
        redirectUrl: null,
        plays: [],
        onboardings: [],
        onboardingsLoading: false,
        onboardingsUpdatedAt: null,
        productUpgradePaths: [{
            id: 10, // Marketplace
            upgradeFrom: [
                17, // Marketplace Lite
            ]
        },{
            id: 12, // Marketplace + Chatbot
            upgradeFrom: [
                10, // Marketplace
                17, // Marketplace Lite
            ]
        },{
            id: 12, // Pro
            upgradeFrom: [
                10, // Marketplace
                16, // Marketplace + Chatbot
                17, // Marketplace Lite
            ]
        },{
            id: 13, // Ultimate
            upgradeFrom: [
                10, // Marketplace
                12, // Pro
                16, // Marketplace + Chatbot
                17, // Marketplace Lite
            ]
        },{
            id: 20, // Marketplace+
            upgradeFrom: [
                19, // Marketplace
            ]
        },{
            id: 22, // Facebook Advertising+
            upgradeFrom: [
                21, // Facebook Advertising
                30, // Facebook AdvertisingLITE
            ]
        },{
            id: 27, // Tiktok Advertising+
            upgradeFrom: [
                34, // Tiktok Advertising
                31, // Tiktok AdvertisingLITE
            ]
        },{
            id: 28, // Snapchat Advertising+
            upgradeFrom: [
                35, // Snapchat Advertising
                32, // Snapchat AdvertisingLITE
            ]
        },{
            id: 29, // Pinterest Advertising+
            upgradeFrom: [
                36, // Pinterest Advertising
                33, // Pinterest AdvertisingLITE
            ]
        },{
            id: 34, // Tiktok Advertising
            upgradeFrom: [
                31, // Tiktok AdvertisingLITE
            ]
        },{
            id: 35, // Snapchat Advertising
            upgradeFrom: [
                32, // Snapchat AdvertisingLITE
            ]
        },{
            id: 36, // Pinterest Advertising
            upgradeFrom: [
                33, // Pinterest AdvertisingLITE
            ]
        }]
    },
    getters,
    actions,
    mutations
};
